.article-nav-link {
    font-size: calc(15rem/16);

    @media screen and (max-width: 767px) {
        font-size: calc(13rem/16);
    }
}
.article-nav-link__prev {
    padding: calc(10rem/16) 0;
}
.article-nav-link__next {
    padding: calc(10rem/16) 0;
}

.article-nav-link__arrow {
    font-size: .75em;
}