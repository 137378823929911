.eyecatcher-item{
    position: absolute;
    width: calc(308rem/16);
    @media screen and (max-width: 1350px){
        display: none;
    }
}

.eyecatcher-item.is-right{
    padding-left: calc(60rem/16);
    right:0;
    text-align: right;
}

.eyecatcher-item.is-left{
    padding-right: calc(60rem/16);
    text-align: left;
    left:0;
}
