.img-teaser{
    position: relative;
}

.img-teaser__body{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: calc(12rem/16) calc(12rem/16) calc(40rem/16);
    background: linear-gradient(to bottom,rgba(255,255,255,0.9) 0%,rgba(255,255,255,0) 100%);
}

.img-teaser__top-title{
    font-size: calc(16rem/16);
    @media screen and (min-width: 768px){
       font-size: calc(17.5rem/16);
    }
}

.img-teaser__title{
    font-size: calc(24rem/16);
    
    @media screen and (min-width: 768px){
       font-size: calc(28rem/16);
    }
}

.img-teaser__img img{
    transition: 1s;
}

.img-teaser:hover .img-teaser__img img{
    transform: translateZ(0) scale(1.05,1.05) rotate(0.02deg);
}