.breadcrumb{
    padding-left: 0;
    padding-right: 0;
    font-size: calc(15rem/16);
    margin-bottom: 0;
}
@media screen and (max-width: 767px) {
    .breadcrumb {
        display: inline-block;
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        flex-wrap: wrap;
        padding-bottom: calc(10rem/16);
        padding-left: calc(16rem/16);
        padding-right: calc(16rem/16);
        margin-bottom:0;
    }
}

.breadcrumb-top-space{
    @media screen and (min-width: 768px){
        margin-top: calc(8rem/16);
    }
    @media screen and (min-width: 1400px){
        margin-top: calc(16rem/16);
    }
}

.img-text-teaser + .breadcrumb-top-space{
    margin-top: 0;
}

.breadcrumb-item{
    opacity: 0.4;
    @media screen and (max-width: 767px) {
        display: inline;
    }
}

.breadcrumb-item.active{
    color: var(--color-default);
    opacity: 1;
}