.badge{
    color: #fff;
    font-family: var(--font-default-bold);
    font-weight: 300;
    text-transform: uppercase;
    font-size: calc(11rem/16);
    padding: calc(4rem/16) calc(8rem/16);
}


.badge--faq{
    background-image: url(/cache-buster-1552481234194/static/build/img/bg/decoration-bg.png);
    background-repeat: no-repeat;
    color: var(--color-default);
}