/* no gutter */
.row.row--gutter-width-0 {
    margin-left:0;
    margin-right:0;
}
.row--gutter-width-0 > .col,
.row--gutter-width-0 > [class^="col"],
.row--gutter-width-0 > [class*=" col"] {
    padding-left:0;
    padding-right:0;
}


/* pixel gutters */
.row.row--gutter-width-10 {
    margin-left:-5px;
    margin-right:-5px;
}
.row--gutter-width-10 > .col,
.row--gutter-width-10 > [class^="col"],
.row--gutter-width-10 > [class*=" col"] {
    padding-left:5px;
    padding-right:5px;
}
.row.row--gutter-width-20 {
    margin-left:-10px;
    margin-right:-10px;
}
.row--gutter-width-20 > .col,
.row--gutter-width-20 > [class^="col"],
.row--gutter-width-20 > [class*=" col"] {
    padding-left:10px;
    padding-right:10px;
}

/*use a media query for bigger row gutters; otherwise the page is scrollable on mobile devices */
@media (min-width: 768px) {
    .row.row--gutter-width-30 {
        margin-left:-15px;
        margin-right:-15px;
    }
    .row--gutter-width-30 > .col,
    .row--gutter-width-30 > [class^="col"],
    .row--gutter-width-30 > [class*=" col"] {
        padding-left:15px;
        padding-right:15px;
    }

    .row.row--gutter-width-40 {
        margin-left:-20px;
        margin-right:-20px;
    }
    .row--gutter-width-40 > .col,
    .row--gutter-width-40 > [class^="col"],
    .row--gutter-width-40 > [class*=" col"] {
        padding-left:20px;
        padding-right:20px;
    }

    .row.row--gutter-width-60 {
        margin-left:-30px;
        margin-right:-30px;
    }
    .row--gutter-width-60 > .col,
    .row--gutter-width-60 > [class^="col"],
    .row--gutter-width-60 > [class*=" col"] {
        padding-left:30px;
        padding-right:30px;
    }

    .row.row--gutter-width-90 {
        margin-left:-45px;
        margin-right:-45px;
    }
    .row--gutter-width-90 > .col,
    .row--gutter-width-90 > [class^="col"],
    .row--gutter-width-90 > [class*=" col"] {
        padding-left:45px;
        padding-right:45px;
    }
}

/* row vertical gutter */

.row--vertical-gutter-30 {
    margin-top: calc(-30rem/16);
    clear: both;
}
.row--vertical-gutter-30 > [class^="col"],
.row--vertical-gutter-30 > [class*=" col"] {
    margin-top: calc(30rem/16);
}

.row--vertical-gutter-30 {
    margin-top: calc(-30rem/16);
    clear: both;
}

.row--vertical-gutter-20 {
    margin-top: calc(-20rem/16);
    clear: both;
}

.row--vertical-gutter-20 > [class^="col"],
.row--vertical-gutter-20 > [class*=" col"] {
    margin-top: calc(20rem/16);
}

.row--vertical-gutter-10 {
    margin-top: calc(-10rem/16);
    clear: both;
}
.row--vertical-gutter-10 > [class^="col"],
.row--vertical-gutter-10 > [class*=" col"] {
    margin-top: calc(10rem/16);
}
