.twitter-typeahead{
    position: static!important;
    display: block!important;
    width: 100%;
}

.tt-menu{
    background: #fff;
    width: 100%;
    border: 0;
    margin-top:0;
    @media screen and (min-width: 768px) {
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    @media screen and (max-width: 767px) {
        height: 40vh;
        overflow-y: scroll;
    }
}

.tt-menu.tt-open{
    @media screen and (min-width: 768px) {
        display: flex !important;
    }
}

.tt-dataset {
    width: 100%;
    padding: 15px;
    margin: 20px 0;
    @media screen and (min-width: 768px) {
        width:auto;
        max-width: 100%;
        flex-basis: 0;
        flex-grow: 1;
        margin: 30px 0;
    }
}

.tt-dataset:first-child {
    @media screen and (min-width: 768px) {
        margin-left: 20px;
    }
}


.tt-dataset:last-child {
    @media screen and (min-width: 768px) {
        margin-right: 20px;
    }
}

.tt-selectable{
    cursor: pointer;
}

.tt-dataset-heading {
    font-family: var(--font-handwriting);
    font-size: 24px;
    margin-bottom: .75rem;
}

.tt-suggestion {
    position: relative;
    font-size: 12.5px;
    padding: 10px 0;
    height: 60px;
    line-height: 40px;
    display: block;
    text-align: left;
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (min-width: 768px) {
        max-width:30vw;
    }
}

.tt-suggestion--w-img {
    padding-left: 65px;
}

.tt-suggestion__img {
    position: absolute;
    left: 0;
    top: 5px;
    height: 50px;
    width: 50px;
}

.tt-dataset  + .tt-dataset  {
    border-left: solid #e6e6e6 1px;
}
.tt-menu.tt-empty {
    display: none !important;
}
.tt-dataset:empty {
    display: none;
}

.tt-hint {
    display: none;
}

/* typeahead styling for czech shop search */
.typeahead--address .tt-dataset {
    margin: 0;
    padding: 10px;
    max-width: none;
}

.typeahead--address .tt-suggestion {
    height: auto;
    padding: 5px;
    white-space: nowrap;
}

.typeahead--address .tt-suggestion.tt-cursor {
    background: var(--color-light-grey);
    display: flex;
}

.typeahead--address .tt-menu {
    margin-top: 0;
    border: none;
    max-height: 50vh;
    overflow: auto;
    border: 1px solid #ddd;
    border-top: none;
    margin-top: -1px;
}

.typeahead--address .tt-menu.tt-open{
    border-top: 1px solid #ddd;
    z-index: 10 !important;
}


/* typeahead styling for productsearch */
.typeahead--products .tt-dataset {
    margin: 0;
    padding: 10px 15px 25px 15px;
}
.typeahead--products .tt-suggestion + form {
    margin-top:20px;
}
.typeahead--products .tt-menu {
    max-height: 50vh;
    overflow: auto;
    width:auto;
    border: 1px solid #eceaea;
    margin-top:0;
}
.typeahead--products .tt-menu.tt-open{
    z-index: 10 !important;
}