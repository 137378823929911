.popover-header {
    position: relative;
    padding-bottom: 0;
    padding-right:1.5rem;
}
.popover__close {
    display: block;
    position: absolute;
    right:.5rem;
    top:.5rem;
    width:1rem;
    height:1rem;
}
.popover-body {
    padding-top: 0;
}
.popover-body a {
    text-decoration: underline;
}