.product-slider{
    /*box-shadow: inset 0 8px 8px -8px #999999, inset 0 -8px 8px -8px #999999;*/
    padding: calc(10rem/16) calc(20rem/16) calc(50rem/16);
}
.product-detail .product-slider {
    @media screen and (min-width: 768px) {
        box-shadow: none;
    }
}
.product-slider__slider .slick-slide img{
    margin: 0 auto;
}

.product-slider .slick-slide{
    margin: 0 10px;
}

.product-slider .slider__arrow {
    color: var(--color-dark);
    top: 45%;
    text-shadow: 0 0 4px white;
}

.product-slider .slider__arrow.slick-prev {
    left: calc(-20rem/16);
}

.product-slider .slider__arrow.slick-next {
    right: calc(-20rem/16);
}
.product-slider .slider__arrow.slick-disabled {
    opacity: .2;
}