@font-face {
    font-family: 'Superclarendon Bold';
    font-style: normal;
    font-weight: 400;
    src: url('/static/fonts/superclarendon-bold.woff2') format('woff2'),
         url('/static/fonts/superclarendon-bold.woff') format('woff');
}

.configurator-select-message-type {
    border: 3px solid #f4a823;
    padding: 1rem;
    margin: 0 auto;
    @media (min-width: 768px) {
        width: 80%;
    }
}

.package-config__message {
    position: absolute;
    overflow: hidden;
    font-family: 'Superclarendon Bold', cursive;
    z-index: 1;
    color: white;
    line-height: 1.1;
}

.configurator-preview-img--spice .package-config__message {
    bottom: 48%;
    top: 28%;
    left: 15%;
    right: 15%;
    text-align: left;
    line-height: 14px;
    @media (min-width: 768px) {
        line-height: 1;
    }
}

.configurator-preview-text {
    white-space: pre-wrap;
    line-height: 1;
}

.configurator-preview-img--spice .configurator-preview-text {
    font-size: 14px;
    @media (min-width: 768px) {
        font-size: 22px;
    }
}

.configurator-preview-img--custom-tea .package-config__message {
    bottom: 54%;
    top: 27.5%;
    left: 12%;
    right: 12%;
    text-align: left;
}

.configurator-preview-img--custom-tea .configurator-preview-text {
    font-size: 17px;
    @media (min-width: 768px) {
        font-size: 28px;
    }
}

@media (min-width: 768px) {
    .configurator-message-input {
        width: 55%;
    }
}


.configurator-theme-selector {
    display: inline-block;
    margin: 0 10px;
}

.configurator-theme-selector__img {
    width: 100px;
    border-radius: 100%;
    border: 4px solid #f4a823;
    margin-bottom: 1rem;
}

.configurator-preview-img {
    width: 50%;
    max-width: 280px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}


.configurator-price {
    font-size: 2rem;
}

.tea-selector-arrow {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;
    z-index: 10;
}

.tea-selector-arrow--tea-1 {
    height: 355px;
    width: 369px;
    left: -123px;
    top: 75px;
}

.tea-selector-arrow--tea-2 {
    height: 355px;
    width: 369px;
    left: -134px;
    top: -37px;
}

.tea-selector-arrow--tea-3 {
    height: 355px;
    width: 369px;
    left: 100%;
    margin-left: -50px;
    top: 30%;
    transform: translateY(-50%);
}

.tea-selector-arrow--tea-4 {
    height: 345px;
    width: 359px;
    left: -290px;
    top: -65%;
}

.tea-selector-arrow--spice-1 {
    height: 355px;
    width: 369px;
    left: -138px;
    top: -25px;
}

.tea-selector-arrow--spice-2 {
    height: 355px;
    width: 369px;
    left: -134px;
    top: -69px;
}