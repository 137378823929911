.cart-merge {
    position: relative;
    margin:0 auto;
    max-width:28rem;
}
.cart-merge__product-list {
    position: relative;
    display: block;
    max-height:30rem;
    overflow-x: hidden;
    overflow-y:auto;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 767px) {
        max-height:15rem;
    }
}
.merge-success-image {
    border-bottom:1px solid #000;
}
.merge-success-image>img {
    display: block;
    margin:0 auto;
    width:100%;
    max-width:12rem;

    @media screen and (max-width: 767px) {
        max-width:8rem;
    }
}
.cart-merge__item{
    padding-bottom: calc(5rem/16);
    margin-bottom: calc(20rem/16);
    border-bottom: 1px solid var(--color-primary);
}
@media screen and (max-width: 767px) {
    .cart-merge__info {
        min-height:calc(100% - 4rem);
    }
}