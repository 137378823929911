.blockquote{
    font-family: var(--font-black);
    color: var(--color-primary);

    font-size: calc(20rem/16);
    line-height: calc(22/20);
    position: relative;
}

.blockquote__body:before,
.tip-item--quote__bg:before,
.blockquote__body:after,
.tip-item--quote__bg:after {
    font-size: 70px;
    opacity: 0.5;
    display: block;
    text-align: right;
    font-family: var(--font-black);
    line-height: 1;
}

.blockquote__body:before,
.tip-item--quote__bg:before {
    content: '„';
    margin-bottom: calc(15rem/16);
}

.blockquote__body:after,
.tip-item--quote__bg:after {
    content: '“';
}

.blockquote__body:after{
    margin-top: calc(12rem/16);

}
