.product-preview {
    padding: 1.5rem;
    @media (min-width: 768px) {
        padding: 2rem .75rem 0;
        font-size: calc(20rem/16);
    }
}


.product-preview__heading {
     font-size: calc(20rem/16);
     @media (min-width: 768px) {
         font-size: calc(25rem / 16);
     }
 }


.product-preview__price {
    line-height: 1;
    font-size: 1.5rem;
    @media (min-width: 768px) {
        font-size: 1.75rem;
    }
}

.product-preview__old-price {
    font-size: calc(16rem/16);
    @media (min-width: 768px) {
        font-size: calc(18rem/16);

    }
}