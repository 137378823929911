.filter-bar{
    background-color: #fff;
    position: relative;
    @media screen and (min-width: 768px){
        z-index:10;
    }
}

.filter-bar.is-affix{
    padding: calc(25rem/16) calc(8rem/16) calc(8rem/16) calc(8rem/16);
}
.filter-bar__col{
    position: static;
}

.filter-bar__col .dropdown{
    position: static;
}

.filter-bar__col .dropdown-menu{
   /* transform: none!important; !* overwrite automatic bootsrap js css *!
    top: 100%!important;!* overwrite automatic bootsrap js css *!
    left: calc(5rem/16)!important; !* overwrite automatic bootsrap js css *!*/
    width: calc(100% - 10px);
    margin: 0 5px;
}

.filter-bar__list{
    @media screen and (min-width: 992px){
        column-count: 5;
    }
    @media screen and (min-width: 768px){
        column-count: 4;
    }
}

.filter-bar__list>li{
    margin-bottom: .5rem;
    @media (max-width: 767px) {
        margin-bottom: 1rem;
    }
}

.filter-bar .custom-checkbox label:hover{
    color: var(--color-primary);
}

.filter-bar legend{
    font-size: calc(16rem/16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    margin-bottom: calc(20rem/16);
}

.btn.btn-filter-bar{
    border: 1px solid #ECEAEA;
    font-family: var(--font-default);
    font-size: calc(17rem/16);
    padding: calc(10rem/16) calc(15rem/16);
    text-transform: none;
}