.thumb-slider {
    position: relative;
    margin-bottom: calc(40rem/16);
}
.thumb-slider__thumbs-wrapper {
    position: relative;
    height:4rem;
    line-height: 0;
}
.thumb-slider__thumbs {
    width: 37%;
    @media screen and (max-width: 767px) {
        width: 60%;
    }
}
.thumb-slider .thumb-slider__thumbs .slick-slide {
    border: 3px solid transparent;
    padding: 5px;
}
.thumb-slider .thumb-slider__thumbs .slick-slide.slick-current.slick-active {
    position: relative;
    border-color: var(--color-orange);
}
.thumb-slider__main-item {
    position:relative;
    padding-bottom:calc(30rem/16);
    @media screen and (max-width: 767px) {
        padding-bottom:calc(10rem/16);
    }
}

.thumb-slider .thumb-slider__thumbs.slick-initialized .slick-slide {
    width: initial !important;
    border: 2px solid transparent;
    margin-left: calc(3rem/16);
    margin-right: calc(3rem/16);
}

/* arrows */
.thumb-slider__arrow {
    color: var(--color-dark);
    position:absolute;
    z-index: 10;
    display: block;
    width: calc(40rem/16);
    height: calc(40rem/16);
    opacity: .7;
}

.thumb-slider__arrow-icon {
    width: calc(40rem/16);
    height: calc(40rem/16);
    display: block;
    background-size:contain;
    background: url(/static/img/icons/slider-arrow-left.svg) 50% 50% no-repeat;
    opacity: .7;
}
.thumb-slider__arrow-icon--right {
    background-image: url(/static/img/icons/slider-arrow-right.svg);
}
.thumb-slider__arrow.slick-disabled {
    opacity: .2;
    cursor:inherit;
}
.thumb-slider__arrow.arrow-prev {
    left:30%;
    top:50%;
    transform: translateX(-100%) translateY(-50%);
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        left:calc(65rem/16);
    }
    @media screen and (max-width: 767px) {
        top:20%;
        left:calc(7rem/16);
        transform: none;
    }
}
.thumb-slider__arrow:focus,
.thumb-slider__arrow:hover {
    outline:none;
    opacity:1;
}
.thumb-slider__arrow.arrow-next {
    right: 30%;
    top:50%;
    transform: translateX(100%) translateY(-50%);
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        right:calc(65rem/16);
    }
    @media screen and (max-width: 767px) {
        top:20%;
        right: calc(7rem/16);
        transform: none;
    }
}