.sticky-bar {
    position: fixed;
    left:0;
    right:0;
    bottom:0;
    padding:.5rem;
    background: #F4F2F3;
    z-index: 20;
    transform: translateY(150%);
    transition: transform .2s;

    @media screen and (min-width: 768px) {
        padding:.5rem .625rem 1rem .625rem;
    }
}
.sticky-bar:before {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    bottom: 100%;
    width: 100%;
    height: calc(10rem/16);
    background: url(/static/img/misc/overlay-footer-top.svg);
    background-size: 100% auto;

    @media screen and (max-width: 767px) {
        bottom: calc(100% - 5rem/16);
    }
}

.sticky-bar.show {
    transform: translateY(0);
}

.sticky-bar__title {
    text-transform: uppercase;
    font-size: calc(15rem/16);
    @media screen and (max-width: 767px) {
        line-height:1;
        font-size: calc(14rem/16);
    }
}

.sticky-bar__value {
    font-size: calc(21rem/16);
    @media screen and (max-width: 767px) {
        line-height:1;
        font-size: calc(19rem/16);
    }
}
