.list-inline--evenly-bordered {
    display: flex;
}

.list-inline--evenly-bordered .list-inline-item {
    position: relative;
    flex: 1 1 auto;
    text-align: center;
    padding: 0 .25rem;
    margin: 0;
}

.list-inline--evenly-bordered .list-inline-item:first-child {
    text-align: left;
}

.list-inline--evenly-bordered .list-inline-item:last-child {
    text-align: right;
}

.list-inline--evenly-bordered .list-inline-item:before {
    position: absolute;
    left: 0;
    content: "";
    top: 4px;
    bottom: 4px;
    border-left: solid 1px var(--color-text-default);
}

.list-inline--evenly-bordered .list-inline-item:first-child:before {
    display: none;
}

.list-inline--gutter-3 .list-inline-item:not(:last-child) {
    margin-right: 1rem;
}