.default-heading {
    position: relative;
    text-align: center;
}
.default-heading--first {
    margin-top: 3rem;

    @media (max-width: 767px) {
        margin-top: 2rem;
    }
}
.default-heading__top{
    position: relative;
}

.default-heading__text {
    position: relative;
    padding: 0 calc(20rem/16);
    background-color: white;
    display: inline-block;

    @media screen and (min-width: 768px){
        max-width: 70%;
    }
}
.container-narrow .default-heading__text{
    display: block;
    word-wrap: break-word;

    @media screen and (min-width: 768px){
        max-width: 100%;
    }
}

.default-heading__sub-text{
    font-size: calc(18rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
    }
}