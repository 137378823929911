/*Margin Helper*/

.margin-top-10 {
    margin-top: 10px;
}
.margin-top-20 {
    margin-top: 20px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}
.margin-bottom-20 {
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .mt-md-2r {
        margin-top: 2rem !important;
    }

}


/*Padding Helper*/


/*Text Helper*/
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-underline {
    text-decoration: underline;
}

.text-transform-none {
    text-transform: none!important;
}

/*Text Color Helper*/
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-muted { color: var(--color-text-muted); }
.text-primary { color: var(--color-primary-text) !important;}

.text-grey { color: var(--color-grey);}
.text-grey-hover:hover {color: var(--color-dark-grey);}


/* Text Font Helper */
.font-default{ font-family: var(--font-default); }
.font-default-bold{ font-family: var(--font-default-bold); }
.font-handwriting{ font-family: var(--font-handwriting); }
.font-black{ font-family: var(--font-black); }

.white-space-initial {
    white-space: initial;
}


.first-letter-big p:first-child:first-letter{
    font-size: calc(40rem/16);
    line-height: calc(60/80);
    padding-top: calc(3rem/16);
    padding-right: calc(5rem/16);
    padding-left: calc(3rem/16);

    font-family: var(--font-black);
    color: var(--color-primary-text);
    float: left;

    @media screen and (min-width: 768px){
        font-size: calc(80rem/16);
        padding-top: calc(5rem/16);
        padding-right: calc(8rem/16);
        padding-left: calc(3rem/16);
    }
}

/* Bg color for faq' */

.bg-general{ background-color: #3aa5c8; }
.bg-packaging{ background-color: #df4342; }
.bg-productsandquality{ background-color: #45a145; }
.bg-shopping{ background-color: #ef8501; }


/* other helpers*/
.alert:empty {display:none;}
.js-go-to-link,
.js-ec-productclick{
    cursor: pointer;
}

.isCursor {
    cursor: pointer;
}

.pointer-none {
    pointer-events: none;
}

.corner-shadow{
    position: relative;
}

.corner-shadow:before,
.corner-shadow:after {
    content: "";
    position: absolute;
    z-index: -1;
    -ms-transform: skew(-2deg,-2deg);
    -webkit-transform: skew(-2deg,-2deg);
    -o-transform: skew(-2deg,-2deg);
    -moz-transform: skew(-2deg,-2deg);
    transform: skew(-2deg,-2deg);
    bottom: 16px;
    box-shadow: 0 15px 5px rgba(0,0,0,0.7);
    height: 50px;
    left: 8px;
    max-width: 48%;
    width: 48%;
}

.corner-shadow:after {
    -ms-transform: skew(2deg,2deg);
    -webkit-transform: skew(2deg,2deg);
    -o-transform: skew(2deg,2deg);
    -moz-transform: skew(2deg,2deg);
    transform: skew(2deg,2deg);
    left: auto;
    right: 8px;
}

.corner-shadow--teaser:before {
    left: 6px;
}

.corner-shadow--teaser:after {
    right: 6px;
}

.img-icon{
    width: calc(42rem/16);
    height: calc(42rem/16);
    background-color: var(--color-primary);
    color: #fff;
    border-radius: 50%;
    display: inline-block;
}

.euro-symbol {
    font-size: 80%;
}

.payment-icon {
    height: 20px;
}

.payment-icon-footer {
    height: 20px;
}

.grecaptcha-badge {
    right:auto !important;
    left:-19999px !important;
}

.checkout-post-logo {
    display: inline-block;
    width: auto;
    height: 1.25rem;
    margin:-.25rem 0 0 1rem;
}

@media (min-width: 768px) {
    .border-md-left {
        border-left: 1px solid var(--color-gray-border);
    }
}

@media (min-width: 768px) {
    .row--middle-border-md > .col-md-6:nth-child(2n) {
        border-left: 1px solid var(--color-gray-border);
    }
}

.overflow-hidden {
    overflow: hidden;
}

@media (max-width: 767px) {
    .no-padding-mobile {
        padding: 0 !important;
    }

}

.border-right-4 {
    border-right: 4px solid black;
}

.icon-in-text {
    vertical-align: -.16em;
}

.recipe__tags__item .icon-in-text {
    vertical-align: .16em;
}

.no-p-margin p {
    margin: 0;
}

.make-p-inline p {
    display: inline;
}

.clear-floats {
    clear: both;
}