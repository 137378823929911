.decoration-teaser{
    background-image: url('/static/img/bg/decoration-bg.png');
    background-repeat: no-repeat;
    text-align: center;
    border-radius: calc(10rem/16);
    cursor: pointer;
}

.decoration-teaser__body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.decoration-teaser__toptitle{
    text-transform: uppercase;
    font-size: calc(20rem/16);
    font-family: var(--font-handwriting);
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding: calc(3rem/16) calc(12rem/16);
    border-radius: calc(10rem/16);
    margin: 0 calc(20rem/16);
    margin-bottom: calc(-5rem/16);

    @media screen and (min-width: 992px){
        padding: calc(3rem/16) calc(20rem/16);
    }
}

.decoration-teaser__title{
    font-size: calc(22rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding: calc(3rem/16) calc(12rem/16);
    border-radius: calc(10rem/16);
    margin: 0 calc(20rem/16);

    @media screen and (min-width: 992px){
        font-size: calc(30rem/16);
        padding: calc(3rem/16) calc(20rem/16);
    }
}



.decoration-teaser__asset{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transform: translateX(100%);
    transition: 2s;
}

.decoration-teaser:hover .decoration-teaser__asset{
    transform: translateX(0);
    transition: 0.8s;
}