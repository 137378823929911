/* 649px is where the badge swaps to the mobile version, so between mobile in 1199 it should be scaled smaller*/
@media (min-width: 649px) and (max-width: 1199px) {
    #minimized-trustbadge-98e3dadd90eb493088abdc5597a70810 {
        transform: scale(.7);
        transform-origin: bottom right;
    }

    #maximized-trustbadge-98e3dadd90eb493088abdc5597a70810 {
        transform: scale(.7);
        transform-origin: bottom right;
    }
}


/*
@media (max-width:767px) {
    [id^=trustbadge-container-]>div>div {
        visibility: hidden !important;
        opacity: 0 !important;
        display: block !important;
        transition: opacity ease-in-out 0.25s;
    }
    [id^=trustbadge-container-].is-visible>div>div {
        visibility: visible !important;
        opacity: 1 !important;
    }
}*/
