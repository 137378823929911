.tip-item {
    position: absolute;
    width: calc(308rem/16);
    padding: 0 calc(60rem/16);
    text-align: center;

    color: var(--color-primary);

    @media screen and (max-width: 1350px){
       display: none;
    }
}

.tip-item.is-right{
    right: 1rem;
}

.tip-item.is-left{
    left: 0;
}

.tip-item__bg{
    background-image: url(/static/img/sprites/sprite-tip-bgs.png);
    background-repeat: no-repeat;
    width: calc(190rem/16);
    height: calc(190rem/16);
    background-position: calc(-10rem/16) calc(-10rem/16);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tip-item__title{
    text-transform: uppercase;
    font-size: calc(20rem/16);
}

.tip-item__text{
    font-family: var(--font-handwriting);
    font-size: calc(22rem/16);
    line-height: calc(26/22);
    /* ie fix */
    max-width: 100%;
}


/* Tip Item Quote */

.tip-item--quote__bg{
    background-image: url(/static/img/sprites/sprite-tip-bgs.png);
    background-repeat: no-repeat;
    background-position: calc(-215rem/16) calc(-5rem/16);
    width: calc(210rem/16);
    height: calc(302rem/16);
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.tip-item--quote__top{
    text-transform: uppercase;
    line-height: calc(24.5/17.5);
    margin-bottom: calc(8rem/16);
}

.tip-item--quote__bottom{
    font-family: var(--font-handwriting);
    font-size: calc(20rem/16);
    line-height: calc(24/20);
}