.store-info {
    font-size: calc(14rem/16);
}

.store-info--small {
    font-size: calc(12rem/16);
}

.store-info--bordered {
    border-bottom: 2px solid var(--color-primary);
    margin-bottom: 1.0em;
    padding-bottom: 0.3em;
}