.fullscreen-img-text-teaser{
    overflow: hidden;
}

.fullscreen-img-text-teaser__body{
    padding: calc(38rem/16) calc(20rem/16);
    position: relative;
    z-index: 1;
    height: 100%;
    @media screen and (min-width: 768px){
        padding: calc(15rem/16) calc(25rem/16);
    }
    
    @media screen and (min-width: 1400px){
        padding: calc(20rem/16) calc(20rem/16) calc(20rem/16) 20%;
    }
}

.is-right .fullscreen-img-text-teaser__body{
    @media screen and (min-width: 1400px){
        padding: calc(20rem/16) 20% calc(20rem/16) calc(20rem/16);
    }
}

.fullscreen-img-text-teaser__content{
    position: relative;
    z-index: 2;
}

.fullscreen-img-text-teaser__title {
    line-height: calc(39 / 36);
    text-transform: uppercase;

    @media screen and (min-width: 768px){
        font-size: calc(26rem/16);
    }

    @media screen and (min-width: 992px){
        font-size: calc(28rem/16);
    }
}

.fullscreen-img-text-teaser__toptitle{
    font-size: calc(18rem/16);

    @media screen and (min-width: 992px){
        font-size: calc(21rem/16);
    }

}


.fullscreen-img-text-teaser--big .fullscreen-img-text-teaser__text{
    font-weight: 700;
}

.fullscreen-img-text-teaser.text-center .fullscreen-img-text-teaser__bottom{
    justify-content: center;
}

.fullscreen-img-text-teaser__price{
    position: relative;
    display: inline-block;
    max-height: calc(80rem/16);

    @media screen and (min-width: 768px){
        max-height: calc(100rem/16);
    }
}

.fullscreen-img-text-teaser__price__circle{
    font-size: calc(80rem/16);
    color: #fff;
    
    @media screen and (min-width: 768px){
       font-size: calc(100rem/16);
    }
}

.fullscreen-img-text-teaser__price__text{
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    line-height: calc(29/20);
    text-transform: uppercase;
    text-align: center;

    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translateX(-50%) translateY(-50%);

    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
    }
}

.fullscreen-img-text-teaser__price__price{
    font-size: calc(19rem/16);
    line-height: 1;

    @media screen and (min-width: 768px){
        font-size: calc(23rem/16);
    }
}

/* big */

/* bigger heading only on portal*/
.fullscreen-img-text-teaser--big .fullscreen-img-text-teaser__title {
    font-size: calc(28rem/16);
}

.fullscreen-img-text-teaser--big.fullscreen-img-text-teaser--portal .fullscreen-img-text-teaser__title{
    font-size: calc(30rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(36rem/16);
    }
}

.fullscreen-img-text-teaser--big .fullscreen-img-text-teaser__toptitle{
    font-size: calc(20rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(22rem/16);
    }
}

/* medium */

/*
.fullscreen-img-text-teaser--medium{
    height: calc(475rem/16);
}*/


/* shifted teaser */


@media (min-width: 768px) {
    .fullscreen-img-text-teaser--shifted {
        overflow: visible;
        padding-bottom: 130px;
    }

    .fullscreen-img-text-teaser--shifted.is-left {
        padding-bottom: 95px;
    }

    .fullscreen-img-text-teaser--shifted .fullscreen-img-text-teaser__col {
        flex: 1;
        max-width: none;
    }

    .fullscreen-img-text-teaser--shifted .fullscreen-img-text-teaser__col--text {
        z-index: 1;
    }

    .fullscreen-img-text-teaser__col--right {
        margin-left: -60px;
    }
    .fullscreen-img-text-teaser__col--left {
        margin-right: -60px;
    }

    .fullscreen-img-text-teaser__col--shifted {
        position: relative;
        top: 130px;
    }

    .fullscreen-img-text-teaser--shifted .fullscreen-img-text-teaser__body {
        padding-left: 11%;
    }
}

@media (max-width: 767px) {
    .fullscreen-img-text-teaser__col--text {
        margin-top: -20px;
    }

    .fullscreen-img-text-teaser--shifted .fullscreen-img-text-teaser__price__circle {
        font-size: calc(120rem/16);
    }

    .fullscreen-img-text-teaser--shifted .fullscreen-img-text-teaser__price {
        max-height: calc(120rem/16);
    }

    .fullscreen-img-text-teaser--shifted .fullscreen-img-text-teaser__price__price {
        font-size: calc(30rem/16);
    }
    .fullscreen-img-text-teaser--shifted .fullscreen-img-text-teaser__link {
        font-size: 1rem;
    }
    
}

.fullscreen-img-text-teaser--shifted .fullscreen-img-text-teaser__body {
    background-size: auto 100%;
    background-repeat: no-repeat;
    min-height: 430px;
    @media (max-width: 767px) {
        background-size: 100% 100%;
    }
}

.fullscreen-img-text-teaser--shifted .fullscreen-img-text-teaser__text {
    text-transform: none;
}


/* full-width teaser */
.fullscreen-img-text-teaser--full-width {
    overflow: visible;
    background-size: auto 100%;
    background-repeat: no-repeat;
    margin-bottom: 11px; /* padding for white overlay */

    position: relative;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 767px) {
        background-size: 100% auto;
    }

}

.fullscreen-img-text-teaser--full-width:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -11px;
    right: 0;
    height: 25px;
    background-image: url('/static/build/img/bg/teaser/teaser-bottom.svg');
    background-repeat: no-repeat;
    background-size: auto 100%;
}

.fullscreen-img-text-teaser--full-width .fullscreen-img-text-teaser__text {
    text-transform: none;
}

@media (min-width: 768px) {
    .fullscreen-img-text-teaser--full-width {
        margin-top: 170px;
    }

    .fullscreen-img-text-teaser--full-width  .fullscreen-img-text-teaser__col {
        min-height: 421px;
    }

    .fullscreen-img-text-teaser--full-width__img {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (max-width: 767px) {
    .fullscreen-img-text-teaser--full-width .fullscreen-img-text-teaser__price__circle {
        font-size: calc(120rem/16);
    }
    .fullscreen-img-text-teaser--full-width .fullscreen-img-text-teaser__price {
        max-height: calc(120rem/16);
    }
    .fullscreen-img-text-teaser--full-width .fullscreen-img-text-teaser__price__price {
        font-size: calc(30rem/16);
    }
    .fullscreen-img-text-teaser--full-width .fullscreen-img-text-teaser__link {
        font-size: 1rem;
    }
}

