@media (min-width: 768px) {
    .nav-tabs--default {
        justify-content: center;
    }
}

.nav-tabs--default .nav-item {
    margin: 0;
}
.nav-tabs--default .nav-link {
    font-size: 1rem;
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    color: white;

    white-space: nowrap;
    padding: .5rem 1rem;
    @media (max-width: 767px) {
        padding: .5rem 2rem;
        padding-left: calc(2rem + 7px);

    }
    @media (min-width: 1400px) {
        padding: .5rem 2rem;
        padding-left: calc(2rem + 7px);
    }
}
.nav-tabs--default .nav-link:not(.active):focus,
.nav-tabs--default .nav-link:not(.active):hover {
    border: 1px solid transparent;
    color: black;
}
.nav-tabs--default .nav-link.active {
    box-shadow: none;
}

@media (min-width: 768px) {
    .nav-tabs--full-border{
        position: relative;
        border-bottom: 0;
        padding-left: 0;
        margin-left: 0;
    }
    .nav-tabs--full-border:before {
        content: '';
        display: block;
        position: absolute;
        left: -9999px;
        right: -9999px;
        bottom: 0;
        height: 5px;
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
        z-index:1;
    }
}

/* full width tabs */
.nav-tabs--full-width {
    display: flex;
}

.nav-tabs--full-width .nav-item {
    flex: 1 1 auto;
    text-align: center;
}

.nav-tabs--default .nav-item {
    box-shadow: 0 0 5px 0 rgba(0,0,0,.5);
}

.nav-tabs--default .nav-item:before {
    content: "";
    position: absolute;
    left: 0;
    right: -7px;
    bottom: 0;
    top: 0;
    background: url(/static/build/img/btn/tabbing-search-results.svg) no-repeat;
    z-index: -1;
    background-position: right;
    background-size: auto 100%;
}

.nav-tabs--default .nav-item {
    position: relative;
    max-width: 300px;
}

.nav-tabs--default .nav-item:nth-child(1) {
    z-index: 10;
}
.nav-tabs--default .nav-item:nth-child(2) {
    z-index: 9;
}
.nav-tabs--default .nav-item:nth-child(3) {
    z-index: 8;
}
.nav-tabs--default .nav-item:nth-child(4) {
    z-index: 7;
}
.nav-tabs--default .nav-item:nth-child(5) {
    z-index: 6;
}
.nav-tabs--default .nav-item:nth-child(6) {
    z-index: 5;
}
.nav-tabs--default .nav-item:nth-child(7) {
    z-index: 4;
}
.nav-tabs--default .nav-item:nth-child(8) {
    z-index: 3;
}

.nav-tabs--default .nav-link.active {
    background-color: transparent;
    border: 1px solid transparent;
}

@media screen and (max-width: 767px) {
    .nav-tabs--scroll-mobile {
        flex-wrap: nowrap;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
    }
    .nav-tabs-scroll-indicator {
        position: relative;
        margin:0 1.5rem;
    }
    .nav-tabs-scroll-indicator__btn {
        transition: opacity 0.2s ease;
        position: absolute;
        font-size: calc(16rem/16);
        right:-2rem;
        bottom:.25rem;
        display: inline-block;
        line-height: 1;
        padding:.5rem;
        color: var(--color-text-default);
    }
    .nav-tabs-scroll-indicator__btn--left {
        right:auto;
        left:-2rem;
    }
}

@media screen and (min-width: 768px) {
    .nav-tabs-scroll-indicator__btn {
        display: none;
    }
}