/* arrows */
.slider__arrow {
  position: absolute;
  left: calc(10rem/16);
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: calc(20rem/16);
  text-shadow: 0 0 6px rgba(0,0,0,.8);
  z-index: 2;
  line-height: 1;
  padding: 7px;
  margin: -7px;
  color: white;
}

.slider__arrow:focus {
    outline: none;
    color: var(--color-light-grey);
    text-shadow: 0 0 10px rgba(0,0,0,.8),
                 0 0 5px rgba(0,0,0,.8);
}

.slider__arrow.slick-next {
  left: auto;
  right: calc(10rem/16);
}

.gallery-slider .slider__arrow.slick-prev{
    left: 10px;
}

.gallery-slider .slider__arrow.slick-next{
    right: 10px;
}


.slider__arrow.slick-disabled {
  opacity: .1;
}


.slider__arrow__icon {
  vertical-align: middle;
}

.slider__arrow__icon--right,
.slider__arrow__icon--left {
    width: 3rem;
    height:3rem;
    display: block;
    content:'';
    background-size:contain;
    background: url(/static/img/icons/slider-arrow-left.svg) 50% 50% no-repeat;
    opacity: .7;
}
.slider__arrow__icon:hover,
.slider__arrow__icon:focus {
    opacity:1;
}
.slider__arrow__icon--right {
    background-image: url(/static/img/icons/slider-arrow-right.svg);
}


/* dots */
.slider--hide-dots .slick-dots {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
}

.slick-dots{
  list-style: none;
  padding-left: 0;
  text-align: center;
  line-height: 1;
}

.slick-dots>li{
  display: inline-block;
  margin: 0 calc(4rem/16);
  width: calc(8rem/16);
  height: calc(8rem/16);
}

.slick-dots>li button{
  background: none;
  color: transparent;
  background: var(--color-primary);
  opacity: 0.4;
  width: calc(8rem/16);
  height: calc(8rem/16);
  padding: 0;
  border-radius: 50%;
  line-height: 1;
  cursor: pointer;
  transition: all 150ms ease;
  box-shadow: none;
  border: none;
  display: block;
}

.slick-dots>li button:hover{
  background: var(--color-primary);
  opacity: 1;
}

.slick-dots>li.slick-active button{
  background: var(--color-primary);
  line-height: 1;
  opacity: 1;
}

/* centered if all items have less width than the whole slider */
.slick--centered .slick-list {
    display: flex;
    justify-content: center;
}

.slick--centered .slick-track {
    width: auto!important;
    transform: none!important;
    margin: 0;
}

.slick--centered .slick-arrow {
    display: none;
}

.slick--centered .slick-cloned {
    display: none;
}