.img-text-teaser{
    position: relative;
    z-index: 1;
}

.img-text-teaser--portal {
    @media screen and (max-width: 767px){
        background: url(/static/img/bg/pattern-papier.jpg) 0 0 repeat;
        padding-bottom:1rem;
    }
}

.img-text-teaser__overlay{
    position: absolute;
    bottom:1.5rem;
    top:1.5rem;
    display: flex;
    align-items: center;
    left:0;
    right: 0;
    z-index: 10;
    pointer-events:none;
    @media screen and (max-width: 767px){
        position:relative;
        left:auto;
        right:auto;
        bottom:auto;
        top:auto;
        margin-top:-2rem;
    }
}

@media screen and (min-width: 768px){
    .img-text-teaser__overlay--additional{
        top:auto;
        bottom:calc(-100rem/16);
    }
    .img-text-teaser .embed-responsive {
        clip-path: polygon(0 99%, 4% 100%, 43% 98%, 51% 100%, 61% 99%, 79% 99%, 85% 100%, 90% 99%, 92% 98%, 100% 99%, 100% 0, 0 0);
    }
}
@media screen and (min-width: 768px) and (max-width: 1199px){
    .img-text-teaser__overlay:not(.img-text-teaser__overlay--additional) .img-text-teaser__body{
        width: 50%;
    }
}

@media screen and (min-width: 768px){
    .img-text-teaser__img:after {
        content: '';
        position: absolute;
        bottom: 1.125rem;
        height: 2rem;
        right: 0;
        left: 0;
        width: auto;
        box-shadow: 0 25px 25px 0 rgba(0,0,0,.5);
        z-index: -1;
    }
}
.img-text-teaser__body{
    position: relative;
    width: 40%;
    padding: calc(38rem/16) calc(20rem/16);
    pointer-events:initial;
    text-wrap: balance;
    word-break: break-word;
    hyphens: auto;
    @media screen and (min-width: 768px){
        margin-left: auto;
        padding: calc(35rem/16) calc(50rem/16);
    }
    
    @media screen and (min-width: 1400px){
        padding: calc(55rem/16) calc(70rem/16);
    }
    @media screen and (max-width: 767px){
        width: 100%;
        padding: calc(30rem/16) calc(20rem/16) calc(45rem/16) calc(20rem/16);
    }
}

.img-text-teaser__body:before{
    content:'';
    width:auto;
    height: auto;
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background: var(--color-primary);
    z-index:-1;
    clip-path: polygon(2% 2%, 23% 2%, 37% 4%, 64% 0, 76% 1%, 96% 0, 99% 5%, 98% 34%, 99% 62%, 100% 94%, 86% 97%, 74% 98%, 63% 99%, 51% 100%, 34% 99%, 12% 100%, 1% 97%, 0 77%, 0 63%, 0 51%, 1% 32%, 1% 8%);
}
.img-text-teaser__body:after{
    position: absolute;
    content:'';
    height:auto;
    right: 1.5rem;
    left: 1.5rem;
    top:1.5rem;
    bottom:1.5rem;
    width: auto;
    z-index: -2;
    box-shadow: 25px 30px 25px 0 rgba(0,0,0,.5);
    transform: rotate(-2deg);
}

.img-text-teaser__body.img-text-teaser__body--papercut{
    position: relative;
    z-index: 5;
}
@media screen and (min-width: 768px){
    .img-text-teaser__body.img-text-teaser__body--papercut{
        width:calc(100% + 5rem);
    }
    .img-text-teaser__body.img-text-teaser__body--papercut-right{
        margin-left:-5rem;
    }
}
@media screen and (max-width: 767px){
    .img-text-teaser__body.img-text-teaser__body--papercut{
        margin:-1rem auto 0 auto;
        width: 90%;
    }
}

.img-text-teaser__body--yellow:before {background: #fab400;}
.img-text-teaser__body--green:before {background: #B8BF7A;}
.img-text-teaser__body--orange:before {background: #F68F4C;}
.img-text-teaser__body--brown:before {background: #E9CEAE;}
.img-text-teaser__body--gold:before {background: #E9CEAE;}
.img-text-teaser__body--red:before {background: #E0615F;}
.img-text-teaser__body--sepia:before {background: #E9CEAE;}
.img-text-teaser__body--pastelPink:before {background: #DD8080;}
.img-text-teaser__body--blue:before {background: #7492CA;}
.img-text-teaser__body--gray:before {background: #E9CEAE;}
.img-text-teaser__body--turquoise:before {background: #77BFA4;}
.img-text-teaser__body--skyblue:before {background: #91C4EA;}
.img-text-teaser__body--plum:before {background: #A58CB7;}

@media screen and (min-width: 768px){
    .img-text-teaser__body--left{
        margin-left: 0;
    }
}
.img-text-teaser__content{
    position: relative;
    z-index: 2;
}

.img-text-teaser__title {
    line-height:1.1;
    font-size: calc(26rem/16);
    color: #fff;
    text-align: center;
    word-break: break-word;
    hyphens: manual;
    @media screen and (min-width: 768px){
        font-size: calc(32rem/16);
    }
    @media screen and (min-width: 992px){
        font-size: calc(40rem/16);
    }
    @media screen and (min-width: 1400px){
        font-size: calc(52rem/16);
    }
}
.img-text-teaser__title:after {
    content: '';
    display: block;
    margin:.5rem 0;
    width: 100%;
    height:.5rem;
    background-image: url(/static/img/bg/white-dots-10px.svg);
    background-position: 50% 50%;
    background-repeat:repeat-x;
}

.img-text-teaser__toptitle{
    font-size: calc(18rem/16);
    color:#fff;

    @media screen and (min-width: 992px){
        font-size: calc(21rem/16);
    }

}
.img-text-teaser__text{
    text-align: center;
}

.img-text-teaser__bottom {
    position: relative;
}

.img-text-teaser.text-center .img-text-teaser__bottom{
    justify-content: center;
}





.img-text-teaser-additional {
    position: relative;
}
.img-text-teaser-additional__inner {
    position: relative;
    /*z-index: -1;*/
    margin:-2rem 0 2rem 0;
    background: url(/static/img/bg/pattern-papier.jpg) 0 0 repeat;
    clip-path: polygon(0 99%, 4% 100%, 43% 98%, 51% 100%, 61% 99%, 79% 99%, 85% 100%, 90% 99%, 92% 98%, 100% 99%, 100% 0, 0 0);
    padding: calc(40rem/16) 1rem calc(100rem/16) 1rem;
    @media screen and (min-width: 1400px){
        padding: calc(100rem/16) 0;
    }
    @media screen and (min-width: 768px){
        padding: calc(85rem/16) 0;
    }
}
.img-text-teaser-additional:after {
    content: '';
    position: absolute;
    bottom: 1.5rem;
    height: 2rem;
    right: 0;
    left: 0;
    width: auto;
    box-shadow: 0 30px 30px 0 rgba(0,0,0,.33);
    z-index: -2;
}
.img-text-teaser-additional__body {
    @media screen and (min-width: 1400px){
        font-size: calc(20rem/16);
    }
}
.img-text-teaser-additional__headline {
    font-family: var(--font-black);
    line-height:1;
    font-size: calc(28rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(32rem/16);
        max-width: 70%;
    }
    @media screen and (min-width: 1400px){
        font-size: calc(48rem/16);
        max-width: 90%;
    }
    @media screen and (min-width: 1400px) and (max-width: 1650px){
        max-width: 78%;
    }
}
.img-text-teaser-additional__headline:after {
    content: '';
    display: block;
    margin:1.25rem 0 .5rem 0;
    width: 100%;
    height:.5rem;
    background-image: url(/static/img/bg/black-dots-10px.svg);
    background-position: 0 50%;
    background-repeat:repeat-x;
}
.img-text-teaser-additional__wysiwyg {
    @media screen and (min-width: 768px){
        max-width: 65%;
    }
    @media screen and (min-width: 1400px){
        max-width: 75%;
    }
}
.img-text-teaser-additional__bauern,
.img-text-teaser-additional__blume,
.img-text-teaser-additional__biene {
    position: absolute;
    display: block;
    pointer-events: none;
}
.img-text-teaser-additional__bauern {
    left:0;
    bottom:0;
    width:auto;
    max-width:53vw;
    @media screen and (min-width: 1800px){
        left: 26%;
        max-width: 50%;
        max-height: 30rem;
        transform: translateX(-50%);
    }
    @media screen and (max-width: 767px){
        display:none;
    }
}
.img-text-teaser-additional__blume {
    right:0;
    bottom:-2rem;
    width:auto;
    height:auto;
    max-width:18rem;
    @media screen and (max-width: 767px){
        bottom:-.5rem;
        max-width:10rem;
    }
}

.img-text-teaser-additional__biene {
    right:2rem;
    top:4rem;
    width:auto;
    height:auto;
    max-width:8rem;
    @media screen and (max-width: 767px){
        right:auto;
        top:auto;
        left:5rem;
        bottom:1.125rem;
        max-width:4rem;
    }
}




/* big */

/* bigger heading only on portal
.img-text-teaser--big .img-text-teaser__title {
    font-size: calc(28rem/16);
}

.img-text-teaser--big.img-text-teaser--portal .img-text-teaser__title{
    font-size: calc(30rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(36rem/16);
    }
}

.img-text-teaser--big .img-text-teaser__toptitle{
    font-size: calc(20rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(22rem/16);
    }
}*/

/* medium */

/*
.img-text-teaser--medium{
    height: calc(475rem/16);
}*/


/* shifted teaser */


@media (min-width: 768px) {
    .img-text-teaser--shifted {
        overflow: visible;
        padding-bottom: 130px;
    }

    .img-text-teaser--shifted.is-left {
        padding-bottom: 95px;
    }

    .img-text-teaser--shifted .img-text-teaser__col {
        flex: 1;
        max-width: none;
    }

    .img-text-teaser--shifted .img-text-teaser__col--text {
        z-index: 1;
    }

    .img-text-teaser__col--right {
        margin-left: -60px;
    }
    .img-text-teaser__col--left {
        margin-right: -60px;
    }

    .img-text-teaser__col--shifted {
        position: relative;
        top: 130px;
    }

    .img-text-teaser--shifted .img-text-teaser__body {
        padding-left: 11%;
    }
}

@media (max-width: 767px) {
    .img-text-teaser__col--text {
        margin-top: -20px;
    }
    .img-text-teaser--shifted .img-text-teaser__link {
        font-size: 1rem;
    }
}

.img-text-teaser--shifted .img-text-teaser__body {
    background-size: auto 100%;
    background-repeat: no-repeat;
    min-height: 430px;
    @media (max-width: 767px) {
        background-size: 100% 100%;
    }
}

.img-text-teaser--shifted .img-text-teaser__text {
    text-transform: none;
}




.img-text-teaser__price {
    position: absolute;
    right:calc(-20rem/16);
    bottom:calc(38rem/16);
    height:calc(40rem/16);
    line-height:calc(40rem/16);
    font-size: 1.25rem;
    white-space: nowrap;
    text-transform: uppercase;

    @media screen and (min-width: 768px){
        bottom:calc(35rem/16);
    }

    @media screen and (min-width: 1400px){
        bottom:calc(55rem/16);
    }
    @media screen and (max-width: 767px){
        bottom:calc(-3rem/16);
        right:calc(-10rem/16);
    }

}
.img-text-teaser__price__price {
    display: inline-block;
    width:calc(100rem/16);
    height:calc(45rem/16);
    line-height:calc(40rem/16);
    text-align: center;
    color:#fff;
    font-size: 1.5rem;
    font-family: var(--font-handwriting);
    background: url(/static/img/bg/pricetag.svg) 50% 50% no-repeat;
    background-size: contain;
}


/* full-width teaser */
.img-text-teaser--full-width {
    overflow: visible;
    background-size: auto 100%;
    background-repeat: no-repeat;
    margin-bottom: 11px; /* padding for white overlay */

    position: relative;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 767px) {
        background-size: 100% auto;
    }

}

.img-text-teaser--full-width:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -11px;
    right: 0;
    height: 25px;
    background-image: url('/static/build/img/bg/teaser/teaser-bottom.svg');
    background-repeat: no-repeat;
    background-size: auto 100%;
}

.img-text-teaser--full-width .img-text-teaser__text {
    text-transform: none;
}

@media (min-width: 768px) {
    .img-text-teaser--full-width {
        margin-top: 170px;
    }

    .img-text-teaser--full-width  .img-text-teaser__col {
        min-height: 421px;
    }

    .img-text-teaser--full-width__img {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

