.bonuscard-container{
    width: 400px;
    height: 240px;
    position: relative;

    @media (max-width: 767px) {
        width: 350px;
        height: 200px;
    }
}

.bonuscard-lemon{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 33%; /*32.631578947*/
}
.bonuscard-herbs-left{
    position: absolute;
    bottom:0;
    left:0;
    width:16%; /*15.806451613*/
    height: 67%; /*67.368421053*/
}
.bonuscard-left{
    position: absolute;
    bottom:0;
    left: 16%;
    width: 9%; /*8.709677419*/
    height: 67%; /*67.368421053*/
}
.bonuscard-text{
    position: absolute;
    top: 33%;
    left:25%;
    width: 45%;
    height: 25%; /*25*/
}
.bonuscard-code{
    background: var(--color-primary);

    position: absolute;
    top: 58%;
    left: 25%;
    width: 45%; /*45.322580645*/
    height: 33%; /*32.5*/
}
.bonuscard-code__container{
    background: #fff;
    border-radius: calc(8rem/16);
    width: 100%;
    height: 100%;
    align-content: center;
}
.bonuscard-code__code{
    width: 75%;
    height: 60%;
}
.bonuscard-code__number{
    height: calc(11rem/16);
    font-size: calc(10rem/16);
}
.bonuscard-bottom{
    position: absolute;
    bottom: 0;
    left: 25%;
    width: 45%;
    height: 9%; /*9.210526316*/
}
.bonuscard-right{
    position: absolute;
    bottom:0;
    right: 22%;
    width: 8%;
    height: 67%; /*67.368421053*/
}
.bonuscard-herbs-right{
    position: absolute;
    bottom:0;
    right:0;
    width: 22%;
    height: 67%; /*67.368421053*/
}