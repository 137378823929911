.confirmation__text {
    font-size: calc(20rem/16);
    @media (min-width: 768px) {
        font-size: calc(24rem/16);
    }
}

.confirmation__text > p {
    margin: 0;
}

.confirmation__icon-wrapper {
    position: absolute;
    width: 90%;
    right: 0;
    bottom: 0;
    transform: translate(50%,50%);
}

.confirmation__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    font-size: calc(24rem/16);
    @media (min-width: 768px) {
        font-size: calc(68rem/16);

    }
}