.loading-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
    z-index: 5;
    /*display: none;*/
}

/*.is-loading .loading-spinner{*/
    /*display: block;*/
/*}*/

.loading-spinner__dots {
    position: absolute;
    left: 50%;
    top: calc(50rem/16);
    transform: translateX(-50%);
}
.loading-spinner__dots > span {
    border-radius: 100%;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    background: var(--color-primary);
    width: 18px;
    height: 18px;
    display: inline-block;
}
.loading-spinner__dots > span:nth-child(1) {
    animation-delay: -0.32s;
}
.loading-spinner__dots > span:nth-child(2) {
    animation-delay: -0.16s;
}
@keyframes sk-bouncedelay {
    0%, 80%, 100% { transform: scale(0);}
    40% { transform: scale(1.0);}
}

.is-loading .loading-spinner {
    display: block;
}
.loading-spinner-container {
    position: relative;
}