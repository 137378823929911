.content-block + .content-block,
.content-block-small + .content-block,
.content-block-big + .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(45rem/16);
    }
}

.content-block-small + .content-block-small,
.content-block + .content-block-small,
.content-block-big + .content-block-small,
.pimcore_area_content + .pimcore_area_content > .content-block-small {
    margin-top: calc(15rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(30rem/16);
    }
}

.pimcore_area_hero-teaser + .pimcore_area_hero-teaser > .content-block.content-block,
.pimcore_area_snippet + .pimcore_area_hero-teaser > .content-block.content-block,
.pimcore_area_hero-teaser + .pimcore_area_snippet > .content-block.content-block {
    margin-top:-1rem;

    @media screen and (min-width: 768px) {
        margin-top: -2rem;
    }
}


/*
.content-block-big + .content-block-big,
.content-block + .content-block-big,
.content-block-small + .content-block-big,
.pimcore_area_content + .pimcore_area_content > .content-block-big {
    margin-top: calc(35rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(60rem/16);
    }
}

*/
