.recipe-recommendation {
    border: 3px solid var(--color-primary);
    border-left: 0;
    border-right: 0;
    padding: 1.5rem 0;
}

.recipe-recommendation__icon {
    float: left;
}

.recipe-recommendation__content {
    margin-left: 80px;
}

.recipe-recommendation__icon {
    border-radius: 100%;
    overflow: hidden;
    width: 60px;
    height: 60px;
}
