.dropdown-toggle--default{
    background-color: #fff;
    border: 1px solid #ECEAEA;
    width: 100%;
    text-align: left;
    font-size: calc(16rem/16);
    line-height: calc(26rem/16);
    text-transform: none;
    font-family: BrandonGrotesque-Light,sans-serif;
    padding: calc(9rem/16) calc(10rem/16);
    padding-right: calc(32rem/16);

    @media screen and (min-width: 992px){
        font-size: calc(17rem/16);
    }
}

.dropdown-toggle.dropdown-toggle--default:after{
    content: var(--icon-arrow-down);
    font-family: iconfont;
    color: var(--color-dark-grey);
    border:none;
    position: absolute;
    top: 50%;
    right: calc(10rem/16);
    transform: translateY(-50%);
    width: auto;
    height: auto;
    font-size: calc(8rem/16);
}

.dropdown--filter-bar .dropdown-menu{
    border-color: #ECEAEA;
    border-radius: 0;
    padding: calc(22rem/16) calc(32rem/16) calc(52rem/16);
    z-index: 4;
    max-height: calc(100vh - 140px);
    overflow-y: auto;
}

.dropdown--filter-bar .dropdown-toggle:focus{
    box-shadow: none;
}

.dropdown--filter-bar.show .dropdown-toggle{
    border-bottom-color: #fff;
}

.dropdown--filter-bar.show .dropdown-toggle:before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: calc(3rem/16);
    background-color: #ffffff;
    bottom: calc(-3rem/16);
    z-index: 5;
}

/* dropdown navbar */

.dropdown--navbar-right-item{
    padding: 10px 0 16px 0;
}

/*
.dropdown--navbar-right-item:not(.dropdown--custom):hover .dropdown-menu{
    display: block;
}
*/
.dropdown--navbar-right-item .dropdown-toggle:after{
    display: none;
}

.dropdown--navbar-right-item .dropdown-menu{
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
    margin: 0;
    border-radius: 0;
    border: 1px solid #ECEAEA;
    border-bottom: 2px solid var(--color-primary);
    padding: calc(32rem/16) calc(15rem/16) calc(20rem/16);
    font-size: calc(13rem/16);
    line-height: calc(16/13);
}

.dropdown--navbar-right-item .dropdown-menu.dropdown-menu--cart{
    right: 0;
    left: auto;
    transform: none;
}

.dropdown--navbar-right-item .dropdown-menu:before{
    content: '';
    position: absolute;
    width: calc(16rem/16);
    height: calc(16rem/16);
    margin-top: 0;
    border-radius: calc(4rem/16);
    top: calc(-8rem/16);
    left: 50%;
    transform: rotate(54.5deg) skewX(16deg);
    margin-left: calc(-8rem/16);
    background-color: #fff;
}

.dropdown--navbar-right-item .dropdown-menu.dropdown-menu--cart:before{
    right: calc(8rem/16);    
    left: auto;
    margin-left: 0;
}

.dropdown--navbar-right-item .dropdown-item{
    text-transform: uppercase;
    text-align: right;
    font-family: var(--font-default-bold);
    color: var(--color-dark-grey);
    padding: calc(3rem/16) calc(10rem/16);
}

.dropdown-menu__close {
    position: absolute;
    right: 0;
    top: 0;
}

/* dropdown cart navbar */

.dropdown-menu--cart{
    width: calc(300rem/16);
    text-align: center;
}

.dropdown-menu--cart__sum__hl{
    text-transform: uppercase;
}

.dropdown-menu--cart__sum__price{
    font-size: calc(26rem/16);
}

.dropdown-menu--cart__item{
    padding-bottom: calc(5rem/16);
    margin-bottom: calc(20rem/16);
    border-bottom: 1px solid var(--color-primary);
}

.dropdown-menu--cart__product-list {
    max-height: 220px;
    overflow-x: hidden;
    overflow-y: auto;
}
