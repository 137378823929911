@media screen and (min-width: 768px) {
    .alert-notification--fixed {
        left: 0;
        right: auto;
        padding-left: 1rem;
        padding-top: 1rem;
    }

    .has-cookie-bar .alert-notification--fixed{
        bottom: calc(88rem/16);
    }
}

.alert-notification .alert-success {
    display: none;
}

.alert-notification--fixed {
    pointer-events: none;
    z-index: 100;
}