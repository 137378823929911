@media (min-width: 1350px) {
    /* side-item version*/
    .store-contact-wrapper {
        position: relative;
    }
    
    .store-contact{
        position: absolute;
        width: calc(308rem/16);
        left: 0;
        padding: 0 1.5rem 0 .75rem;
        margin-right: 12%;
        z-index: 1;
    }

    .store-contact-wrapper + .content-block {
        min-height: calc(460rem/16);
    }

    .store-contact-wrapper + .location-detail-content {
        min-height: 40rem;
    }
}


.store-contact__title {
    margin: 1rem 0;
}

.store-contact__text {
    order: 1;
}

@media (min-width: 768px) and (max-width: 1349px) {
    .container.store-contact-wrapper {
        /* size of container-narrow */
        max-width: 43.25rem;
    }

    /* cols have full width of element*/
    .store-contact{
        position: static;
        width: auto;
    }

    .store-contact-col {
        flex: 0 0 50%;
    }

    .store-contact__title {
        margin-top: 0;
    }

    .store-contact__map {
        order: 2;
    }
}

.store-contact-col__share {
    @media screen and (min-width: 1760px) {
        position: absolute;
        transform-origin: bottom right;
        transform: rotate(90deg);
        margin: 0;
        right: -66vw;
        bottom: -25%;
    }
}




.store-contact__divider {
    height: 1px;
    background-color: black;
    margin-bottom: .5rem;
    margin-top: .5rem;
}