.teaser-badge {
    color: var(--color-orange);
    background-color: var(--color-orange-pastel);
    transform: rotate(-45deg);
    padding: 2px 30px;
    position: absolute;
    left: -28px;
    top: 15px;
    font-family: var(--font-handwriting);
    font-size: 1rem;
    box-shadow: -1px 2px 5px 0 rgba(0,0,0,0.25);
    z-index: 1;
}