.product-teaser{
    text-align: center;

    @media screen and (max-width: 767px){
        border-bottom: 1px solid #eceaea;
        padding-bottom: calc(25rem/16);
    }

    /* smaller devices (tablet) and devices that don't support hover show the cart-button by default */
    @media (max-width: 991px), (hover: none) {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.product-slider__slider .product-teaser{
    @media screen and (max-width: 767px){
        border: none;
    }
}

.product-grid__content--two-col .product-teaser{
    @media screen and (max-width: 767px){
        border-bottom: none;
        padding-bottom: 0;
    }
}

.product-teaser--aside{
    width: calc(284rem/16);
    padding: 0 calc(20rem/16);
    @media screen and (min-width: 1400px){
        float: left;
        margin-left: 3rem;
        margin-bottom: 2rem;
    }

    @media screen and (min-width: 768px) and (max-width: 1399px){
        margin: 0 auto;
        margin-bottom: calc(60rem/16);
    }

    @media screen and (max-width: 767px){
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;
    }
}

.product-teaser__title{
    font-size: calc(14rem/16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    margin-bottom: calc(5rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(13rem/16);
    }

    @media screen and (min-width: 992px){
        font-size: calc(16rem/16);
    }
}

.product-teaser__price{
    font-size: calc(18rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
    }
}

.product-teaser__old-price {
    font-size: calc(13rem/16);
    margin-left: .125rem;
}

.product-teaser--aside .product-teaser__hover-container{
    display: none;
}

.product-teaser__cart-btn-direct{
    display: none;
}

.product-teaser--aside .product-teaser__cart-btn-direct{
    display: inline-block;
}

.product-teaser__base-price--aside {
    display: none;
}

.product-teaser--aside .product-teaser__base-price--aside {
    display: block;
}

.product-teaser__body{
    position: relative;

    /* smaller devices (tablet) and devices that don't support hover show the cart-button by default */
    @media (max-width: 991px), (hover: none) {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
}

.product-teaser__hover-container .btn-default{
    font-size: calc(12rem/16);
    margin-bottom: calc(5rem/16);
}

.product-teaser__hover-container.product-teaser__hover-container p {
    margin: 0;
}

.product-teaser__hover-container{
    margin-top: calc(10rem/16);
    /* smaller devices (tablet) and devices that don't support hover show the cart-button by default */
    @media (max-width: 991px), (hover: none) {
        margin-top: auto;
    }
}
/* smaller devices (tablet) and devices that don't support hover show the cart-button by default */
@media screen and (hover: hover) and (min-width: 992px){
    .product-teaser__hover-container {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateY(30px) translateX(-50%);
        margin-top: 0;
        transition: transform 500ms ease, opacity 500ms ease;
        transition-delay: 0.2s;
    }

    .product-teaser:not(.product-teaser--aside):hover .product-teaser__hover-container {
        opacity: 1;
        transform: translateY(0px) translateX(-50%);
    }

    .product-teaser__content{
        opacity: 1;
        transition: all 500ms ease;
        transition-delay: 0.4s;
    }

    .product-teaser:not(.product-teaser--aside):hover .product-teaser__content{
        opacity: 0;
        transition-delay: 0s;
        transform: translateY(15px);
    }
}

/* smaller devices (tablet) and devices that don't support hover show the cart-button by default */
@media (max-width: 991px), (hover: none) {
    .product-teaser:not(.product-teaser--aside) .product-teaser__hover-container{
        opacity: 1;
    }
}

.product-teaser__content{
    @media screen and (max-width: 767px){
        max-width: calc(285rem/16);
        margin: 0 auto;
    }
}

.product-grid__content--two-col .product-teaser__content{
    @media screen and (max-width: 767px){
        width: auto;
    }
}

.product-teaser__img{
    position: relative;

    @media screen and (max-width: 767px){
        height: calc(185rem/16);
        width: auto;
        margin: 0 auto;
    }
}

.product-teaser__badge{
    position: absolute;
    right: 0;
    bottom: calc(8rem/16);
    width: calc(89rem/16);
    height: calc(37rem/16);
    padding-top: 0.375rem;
    color:#fff;
    text-transform: uppercase;
    font-size: 1.125rem;
    letter-spacing: calc(1rem/16);
    text-indent: calc(2rem/16);
    font-family: var(--font-handwriting);
    background:url(/static/img/bg/new.png) 50% 50% no-repeat;
    background-size: contain;
    line-height: 1.8;
    z-index: 2;
}

@media screen and (max-width: 767px){
    .slider .product-teaser__badge{
        right: 3.5rem;
        bottom: 1rem;
    }
}


.product-teaser .btn-cart{
    @media screen and (max-width: 767px){
        width: calc(285rem/16);
        font-size: calc(14rem/16);
        padding: calc(8rem/16) calc(15rem/16)
    }
}

.product-teaser .btn-cart>.icon {
    font-size: calc(20rem/16);
    margin-right: calc(15rem/16);
}