.ui-datepicker.ui-datepicker {
    font-size: 1rem;
    z-index: 1055!important;
}

.datepicker .form-control {
    padding-right: 2.5rem;
}

.datepicker .form-control[readonly] {
    background-color: #fff;
}

.datepicker:before {
    font-family: "iconfont";
    content: var(--icon-calendar);
    speak: none;
    position: absolute;
    bottom: 0;
    top: 50%;
    transform: translateY(-50%);
    right: calc(15rem/16);
    font-size: calc(16rem/16);
    pointer-events: none;
    display: flex;
    align-items: center;
    color: var(--color-dark-grey);
}