.speech-bubble-item {
    margin-bottom: calc(28rem/16);
}

.speech-bubble-item__bubble {
    position: relative;
    width: 100%;
    background-color: var(--color-orange);
}
.accordion .speech-bubble-item__bubble {
    width: 98%;
}
.speech-bubble-item__bubble:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 100%;
    left: 0;
    padding-top: calc( 21 / 718 * 100%);
    background: url('/static/img/bg/sprechblase_oben.svg');
    background-size: 100% auto;
    margin-bottom: -1px;
}
.speech-bubble-item__bubble:after {
    content: '';
    position: absolute;
    right: 0;
    top: 100%;
    left: 0;
    padding-top: calc( 44 / 718 * 100%);
    background: url('/static/img/bg/sprechblase_unten.svg');
    background-size: 100% auto;
    margin-top: -1px;
}
.speech-bubble-item__author {
    font-family: var(--font-handwriting);
    color: var(--color-orange);
    font-size: calc(25rem/16);
    text-transform: uppercase;
    margin-top: 6%;
    margin-left: calc(25rem/16);
    text-align: left;

    @media screen and (max-width: 767px) {
        margin-left: calc(10rem/16);
    }
}
.speech-bubble-item__text {
    font-size: calc(18rem/16);
    line-height: calc(25/16);
    text-align: center;
    padding: calc(25rem/16);
}

.speech-bubble-area .accordion .card {
    background-color: transparent;
    border: none;
}
.speech-bubble-area .accordion .card-header {
    background-color: transparent;
    border: none;
}