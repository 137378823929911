.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table,
.wysiwyg > .table-responsive {
    margin-bottom: 2em;
}

.wysiwyg > table,
.wysiwyg > .table-responsive {
    margin-top: 1rem;
}

.wysiwyg > p{
    margin: calc(14rem/16) 0;

    @media screen and (min-width: 768px){
        margin: calc(17rem/16) 0;
    }
}

.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
    margin-top: calc(18rem/16);
    margin-bottom: 0;
    
    @media screen and (min-width: 768px){
       margin-top: calc(24rem/16);
    }
}


.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary-text);
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary-text);
    text-decoration: underline;
}

.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5{
    color: var(--color-primary-text);
}

.wysiwyg h4,
.wysiwyg h5 {
    font-family: var(--font-default-bold);
    text-transform: uppercase;
}

.wysiwyg h5 {
    font-size: 1rem;
}

.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
/* test9 */