.product-detail__subheadline {
    font-size: calc(30rem/16);
    font-family: var(--font-black);
    margin-top: 0;
    padding-bottom: calc(25rem/16);

    @media screen and (max-width: 767px) {
        font-size: calc(25rem/16);
    }
}

.product-information__title {
    line-height: 33px;
    letter-spacing: 0.5px;

    @media screen and (max-width: 767px) {
        font-size: calc(24rem/16);
        line-height: 1;
    }
}
.product-information__subtitle {
    font-size: calc(30rem/16);
    line-height: 1.25;
    letter-spacing: 0.5px;

    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
    }

}

.product-information__categories {
    font-size: calc(15rem/16);
}

.product-information__categories-item:not(:first-child) {
    padding-left: calc(10rem/16);
}
.product-information__categories-item:not(:last-child) {
    padding-right: calc(13rem/16);
    margin-right: 0;
    position: relative;
}
.product-information__categories-item:not(:last-child):after {
    content: '|';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.product-information__description button {
    display: block;
    font-size: calc(15rem/16);
}

.product-information__content-img-wrapper {
    height: calc(42rem/16);
    width: calc(42rem/16);
}
.product-information__content-list {
    position: relative;
    margin-top: 1rem;
    
    @media screen and (max-width: 767px) {
        margin-left: calc(-12rem/16);
        margin-right: calc(-12rem/16);
    }
}
.product-information__content-item {
    position: relative;
    padding: calc(15rem/16);
    border: 2px solid #D4D4D4;
    background-color: transparent;
    @media (min-width: 768px) {
        padding: calc(15rem/16) calc(38rem/16);
    }
}
.product-information__content-item:hover {
    border-color: var(--color-orange-pastel);
    z-index: 2;
    transition: border-color 0.5s ease;
}
.custom-radio__input:checked + .custom-radio__label .product-information__content-item,
.custom-radio--box.active .custom-radio__label .product-information__content-item {
    border: 3px solid var(--color-orange);
    z-index: 1;
}

.product-informtion__divider {
    position: relative;
    padding-left: calc(7rem/16);

    @media screen and (min-width: 768px) {
        padding-left: calc(20rem/16);
        margin-left: calc(15rem/16);
    }
}
.product-informtion__divider:before {
    position: absolute;
    left: 0;
    top: 0;
    content: '|';
    height: 100%;
    width: calc(5rem/16);
}

.product-information__cart {
    position: relative;
}
.product-information__button-row {
    @media (min-width: 1400px) {
        justify-content: space-between;
    }
}
.product-information__share {
    margin-top: calc(15rem/16);
    margin-bottom: calc(30rem/16);
    @media screen and (min-width: 1760px) {
        position: absolute;
        transform-origin: bottom right;
        transform: rotate(90deg);
        margin: 0;
        right: -16%;
        bottom: 0;
    }
}
.product-information__mail {
    position: fixed;
    right: 0;
    top: 61%;
    border-radius: 4px;
    overflow: hidden;
    z-index: 1;

    @media screen and (max-width: 1199px) {
        display: none;
    }
}

.product-information__sticky-add-to-cart {
    display: block;
    @media (max-width: 767px) {
        text-align: center;
    }
}

.product-information__old-price {
    font-size: calc(13rem/16);
}

.product-information__price {
    line-height: 1.2;
}

.product-detail-availability {
    font-family: var(--font-default-bold);
}
.product-detail-availability>.icon {
    position: relative;
    top:.125rem;
    margin-right:.5rem;
}
.product-detail-availability .is-available {
    color:#bbc934;
}
.product-detail-availability .is-not-available {
    color:red;
}

.product-detail-informations {
    font-size: calc(15rem/16);
    @media (max-width: 767px) {
        font-size: calc(13rem/16);
    }
}