.share-btn {
    font-family: var(--font-default-bold);
    color: var(--color-dark-grey);
    text-transform: uppercase;

}

.share-btn__btn {
    @media screen and (min-width: 1760px) {
        font-size: calc(14rem / 16);
    }
}

@media screen and (min-width: 1760px) {
    .shariff li {
        transform: rotate(-90deg);
    }

    .shariff li:hover {
        transform: translateY(-4px) rotate(-90deg);
    }
}


.shariff .orientation-vertical li, .shariff .orientation-vertical li a {
    display: flex;
}
.shariff .orientation-horizontal li, .shariff .orientation-horizontal li a {
    display: inline-flex;
}

.shariff ul {
    display: flex;
    padding-left: 0;
    list-style: none;
    margin-top: 0;
}

.shariff li {
    border: none;
}

.shariff li, .shariff li a {
    color: #fff;
    background-color: transparent;

    text-align:center;
    justify-content: center;
    align-items: center;
    font-size:calc(13rem/16);
    width: calc(20rem/16);
    height:calc(20rem/16);
    transition: all 0.2s ease;
}

.shariff li:hover {
    transform: translateY(-4px);
}

.shariff li + li {
    margin-left: calc(4rem/16);
}

.shariff-button .fab, .shariff-button .fas {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0);
}

.shariff-button.facebook a{
    background-color: #3b5998;
}

.shariff-button.twitter a{
    background-color: #1da1f2;
    font-size: calc(12rem/16);
}

.shariff-button.pinterest a{
    background-color: #cb2027;
}

.shariff-button.mail a{
    background-color: #848484;
    font-size: calc(10rem/16);
}

.shariff-button.facebook .fab:before {
    content:var(--icon-facebook);
}

.shariff-button.twitter .fab:before {
    content:var(--icon-twitter);
}

.shariff-button.pinterest .fab:before {
    content:var(--icon-pinterest);
}

.shariff-button.mail .fas:before {
    content:var(--icon-mail);
}