.products-table {
    display: table;
    width: 100%;
    border-collapse: collapse
}

.products-table-row {
    display: table-row;
    border-bottom: 1px solid var(--color-gray-border);
}
.products-table-row:last-of-type {
    border-bottom: 0;
}

.products-table-row--disabled {
    opacity: .6;
}

.products-table-row--head .products-table-row__cell {
    text-transform: uppercase;
    font-size: 13px;
    font-family: var(--font-black);
    color: var(--color-orange);
}

.products-table-row__cell {
    display: table-cell;
    padding: 15px .25rem;
    @media (min-width: 768px) {
        vertical-align: middle;
    }
}

.products-table-remove {
    width: 20px;
    height: auto
}

.product-image {
    width: 110px;
    padding-right: 10px;
    height: 150px;
}

.basket-amount {
    width: 70px;
    text-align: center;
}

.products-table__button {
    font-size: calc(20rem/16);
}

.product-price {
    font-size: calc(21rem/16);
    padding: 0 .5rem;
    line-height: 1.2;
}
.product-price__old-price {
    font-size: calc(13rem/16);
}
@media (max-width: 767px) {
    .products-table-row__cell {
        position: relative;
        display: inline-block;
        padding: 10px;
    }

    .products-table-row__cell.has-discount {
        top:11px;
    }

    .products-table-row__cell--title {
        min-width: 70%;
    }

    .product-amount {
        min-width: 40%;
    }

    .product-price {
        min-width: 30%;
    }

    .products-table-row__buttons {
        text-align: right;
    }

    .product-image {
        width: 4.5rem;
        padding: 0;
        vertical-align: top;
        height: 65px;
    }
}