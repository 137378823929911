.form-group{
    position: relative;
    @media screen and (max-width: 767px){
       margin-bottom: calc(22rem/16);
    }
}

.form-group:not(.form-group--standard):not(.form-group--bg):after {
    content: '';
    position: absolute;
    right: 0;
    top: calc(8rem/16);
    height: calc(30rem/16);
    width: 1px;
    background-color: #e0e0e0;
}

/*.form-group.has-error:after {
    display: none;
}*/

.form-group__btn{
    position: absolute;
    top: calc(1rem/16);
    bottom: calc(1rem/16);
    right: calc(1rem/16);
    background-color: transparent;
    border: none;
    vertical-align: middle;
    display: flex;
    align-items: center;
    padding: 0 calc(8rem/16);
    cursor: pointer;
    color: var(--color-dark-grey);
}

.form-control--standard.has-value ~ .form-group__btn{
    background-color: var(--color-dark-grey);
    color: #fff;
}

.form-control {
    border-radius: 0;
    height: calc(46rem/16);
    border: none;
    padding-top: calc(16rem/16);
    font-size: calc(18rem/16);
}

.form-control--standard{
    border: 1px solid #ECEAEA;
    padding-top: calc(12rem/16);
}

.form-control--standard:focus{
    border: 1px solid #ECEAEA;
}

.form-group--search .form-control{
    padding-right: calc(36rem/16);
}

.form-group--small .form-control{
    height: 2.25rem;
    padding: .5rem;
}


@media (max-width: 767px) {
    .form-group--small .form-control.basket-amount{
        height: 2.5rem;
    }
}


.parsley-errors-list,
.form-group__initial-message {
    list-style: none;
    padding-left: calc(12rem/16);
    font-size: calc(13rem/16);
    color: var(--color-danger);
    line-height: calc(15rem/16);
}

.has-error .form-group__initial-message,
.has-success .form-group__initial-message {
    display: none;
}

.custom-checkbox .parsley-errors-list {
    position: static;
    padding-left: 22px;
}

.form-group--standard .parsley-errors-list {
    margin-top: .25rem;
}

.has-error .form-control{
    color: var(--color-danger);
}

.has-error .floating-label {
    color: var(--color-danger);
}

.row--form .form-group{
    margin-bottom: 0;
}
.form-contact {
    position: relative;
}
.form-contact__decoration {
    position: absolute;
    bottom: 0;
    right: -45%;
    width: 40%;
    height: 100%;
}

.form-contact__decoration img {
    max-height: 100%;
}

.form-contact .form-contact__title {
    width: 70%;
}
.form-contact .form-contact__message-input {
    height: calc(150rem/16);
    border-top: 1px solid #e0e0e0;
}
.form-contact .has-error .form-contact__message-input {
    border-top: 1px solid var(--color-danger);
}
.form-contact .form-contact__message-label {
    top: calc(16rem/16);
    left: 0;
    padding: 0;
}
.form-contact .form-contact__label {
    bottom: calc(16rem/16);
    left: 0;
    padding: 0;
}
.form-contact .custom-checkbox {
    border: none;
}

@media screen and (min-width: 768px){
    .row--form{
        border-bottom: 1px solid #e0e0e0;
        padding-top: .25rem;
        padding-bottom: .25rem;
    }
}

@media screen and (max-width: 767px){
    .row--form > .col,
    .row--form > [class^="col"],
    .row--form > [class*=" col"]{
        border-bottom: 1px solid #e0e0e0;

    }
}

.input-group--btn-absolute{
    position: relative;
}

.input-group--btn-absolute .btn{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: calc(46rem/16);
    font-size: calc(22rem/16);
    line-height: 0;
}

/* input with custom background */
.form-control.form-control--bg {
    padding: 10px 15px;
    height: auto;
    font-size: 1rem;
}
.form-control.form-control--bg,
.form-control.form-control--bg:focus {
    background: url(/static/build/img/bg/paint-bg.png);
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
}
