.progress-list {
    display: flex;
    justify-content: center;
}
.progress-list__item {
    display: inline-block;
    width: 19%;
    vertical-align: top;
    opacity: 0.5
}

.progress-list__item__completed,
.progress-list__item__no {
    font-family: ClarendonBT-Roman,Times,serif;
    font-size: calc(44rem/16);
    text-align: center;
}

.progress-list__item.progress-list__item--completed,
.progress-list__item.progress-list__item--active {
    opacity: 1
}

.progress-list__item__completed {
    display: none;
}

.progress-list__item.progress-list__item--completed .progress-list__item__completed {
    display: block;
}

.progress-list__item.progress-list__item--completed .progress-list__item__no {
    display: none;
}

.progress-list__item__title {
    font-size: calc(12rem/16);
    text-transform: uppercase;
    text-align: center;
    color: #6b6c6d
}


@media (max-width: 767px) {
    .progress-list__item__completed,
    .progress-list__item__no {
        font-size: 1.5rem;
    }

    .progress-list__item__title {
        font-size: calc(7rem/16);
    }
}

.basket-progress {
    margin-top: 3rem;
}

@media (max-width: 415px) {
    .basket-progress-subline {
        display: none
    }

    .basket-progress__list .progress-list__item__title {
        display: none
    }
}