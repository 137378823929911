.custom-checkbox{
    position: relative;
}
.custom-checkbox label{
    display: inline-flex;
    position: relative;
    transition: all 200ms ease;
}
.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}.custom-checkbox:after {
     clear: both;
 }
.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-checkbox__box {
    position: relative;
    float: left;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: 1px solid #c4c6c7;
    background: #ffffff;
    width: calc(16rem/16);
    height: calc(16rem/16);
    margin-top: .2em;
}

.custom-checkbox--small .custom-checkbox__box{
    width: calc(14rem/16);
    height: calc(14rem/16);
    margin-top: calc(3rem/16);
    border-color: var(--color-primary);
}

.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(7rem/16);
    color: var(--color-primary);
    visibility: hidden;
    /* todo: get icon for iconfont? */
    content: "\2713";
    font-family: Arial;
    font-size: 12px;
}

.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}
.has-error.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
}
.custom-checkbox__text p {
    margin:0;
}
.custom-checkbox--small .custom-checkbox__text{
    font-size: calc(14rem/16);
}

.custom-checkbox__text a{
    color: var(--color-primary);
}

/* For Checkbox in Bootstrap Dropdown */
.custom-checkbox__input.custom-checkbox__input--full-and-hide{
    clip: auto;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
}

.icon-check:before {
    content: "\2713";
    font-family: Arial;
}