.ribbon:before {
    content: '';
    background-image: url(/static/build/img/misc/ribbon-end-left-orange.png);
    background-size: 48px 36px;
    width: 48px;
    height: 36px;
    display: block;
    position: absolute;
    right: 100%;
    top: 0;
}

.ribbon {
    background-color: #f8ac2b;
    height: 25px;
    color: white;
    padding: 1px 10px 3px 6px;
    margin-left: 48px;
    position: relative;
    font-size: 1rem;
}


/* mobile ohne schleife */
@media (max-width: 767px) {
    .ribbon--xs-no-mesh:before {
        display: none;
    }
    .ribbon--xs-no-mesh {
        margin-left: 0;
    }
}