.main-content.error-page-main{
    padding-top: 0;
}

.error-page {
    position: relative;
    top: 0;
    min-height: calc(1000rem/16);
    background-image: url(/static/img/bg/bg-error.jpg);
    background-size: 100% calc(1000rem/16);

    @media screen and (max-width: 767px) {
        min-height: calc(850rem/16);
        background-size: inherit;
    }
}

.error-page__wrapper {
    position: relative;
    width: calc(668rem/16);
    top: calc(312rem/16);
    margin: 0 auto;

    @media screen and (max-width: 767px) {
        top: calc(200rem/16);
        width: auto;
        margin: 0 15px;
    }
}
.error-box {
    background-color: white;
    padding: calc(30rem/16) calc(30rem/16) calc(45rem/16);
    text-align: center;
    position: relative;
    z-index: 1;
    @media (min-width: 768px) {
        padding: calc(30rem/16) calc(75rem/16);
    }
}
.error-box__title {
    text-transform: uppercase;
    font-size: calc(13rem/16);
}
.error-box__text {
    font-size: calc(24rem/16);
    font-family: var(--font-black);
    color: #ae373f;
    margin-top: calc(12rem/16);
    margin-bottom: calc(24rem/16);
}

/* illustrations */
.error-img-2 {
    position: absolute;
    top: calc(-230rem/16);
    right: calc(-160rem/16);
    z-index: 2;
    width: calc(308rem/16);
    height: calc(400rem/16);
    background-image: url(/static/img/misc/error-ballon.png);
    background-size: contain;

    @media screen and (max-width: 767px) {
        top: calc(-96rem/16);
        right: calc(-1rem/16);
        width: calc(120rem/16);
        height: calc(155rem/16);
    }
}
.error-img-3 {
    position: absolute;
    display: block;
    top: calc(1rem/16);
    left: calc(-130rem/16);
    z-index: 3;
    width: calc(91rem/16);
    height: calc(60rem/16);
    background-image: url(/static/img/misc/error-bird.png);
    background-size: contain;

    @media screen and (max-width: 767px) {
        display: none;
    }
}

.footer.nomargin{
    margin: 0;
}
