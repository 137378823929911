.headline{
    position: relative;
    margin-bottom: calc(16rem/16);
    margin-top: calc(35rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(40rem/16);
    }
}

.page-heading.headline{
    margin-top: 0;
}

.headline__title:after{
    content: "";
    display: block;
    margin: 0 auto;
    width: 40%;
    height: calc(3rem/16);
    background-color: var(--color-primary-text);
    margin-top: calc(12rem/16);
}
.headline__title--bw:after{
    background-color:#fff;
}

.js-animate-into-viewport.headline__title:after {
    transform: scaleX(0);
}

.animate-into-viewport--animate.headline__title:after {
    transform: scaleX(1);
    transition: 1.2s
}

.content-heading-small__title{
    border-bottom: 3px solid var(--color-default);
    padding-bottom: calc(3rem/16);
    display: inline-block;
    font-family: var(--font-default);
    font-size: calc(24rem/16);
}

.headline--number .headline__title {
    font-size: calc(72rem / 16);
    line-height: 0.7;
}

.headline--number .headline__title:after {
    width: 1em;
}

.headline--number + .headline {
    margin-top: 1rem;
}