.img-text-teaser--papercut-bottom,
.img-text-teaser--papercut-top{
    position: relative;
}
.img-text-teaser--papercut-top:before,
.img-text-teaser--papercut-bottom:after{
    content: '';
    position: absolute;
    bottom: 2rem;
    height: 2rem;
    right: 0;
    left:0;
    width: auto;
    z-index: -2;
    box-shadow: 0 30px 30px 0 rgba(0,0,0,.33);
}
.img-text-teaser--papercut-top:before{
    bottom:auto;
    top:2rem;
    transform: rotate(180deg);
}

.img-text-teaser__papercut {
    padding:2rem 0;
    background: url(/static/img/bg/pattern-karton.jpg) 0 0 repeat;

    @media screen and (min-width: 768px) {
        padding:4.5rem 0;
    }
}

.img-text-teaser__papercut--bottom {
    clip-path: polygon(0 98%, 13% 100%, 46% 99%, 64% 100%, 90% 99%, 100% 100%, 100% 0, 0 0);
}

.img-text-teaser__papercut--top {
    clip-path: polygon(0 2%, 6% 0, 33% 2%, 47% 1%, 72% 1%, 87% 2%, 100% 0, 100% 100%, 0 100%);
}
.img-text-teaser__papercut--top.img-text-teaser__papercut--bottom {
    clip-path: polygon(0 2%, 6% 0, 43% 1%, 70% 2%, 94% 1%, 100% 0, 100% 29%, 100% 100%, 92% 99%, 79% 98%, 61% 99%, 41% 98%, 8% 100%, 0 98%);
}

.img-text-teaser__img-papercut {
    display: block;
    position: relative;
    padding:.5rem;
    background: #fff;
    transform: rotate(2deg);
    box-shadow: 0 6px 12px 0 rgba(0,0,0,.25);
}
.img-text-teaser__img--papercut-left {
    transform: rotate(-2deg);
}