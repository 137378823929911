.event-teaser{
    position: relative;
    height: 100%;
    
    @media screen and (max-width: 767px){
       overflow: hidden;
    }
}

.event-teaser__body{
    padding: calc(12rem/16);
    position: relative;
    z-index: 2;
}

.event-teaser__toptitle{
    font-family: var(--font-handwriting);
    margin: 10px 0 5px;
}

.event-teaser__title{
    font-size: calc(22rem/16);
    position: relative;
    padding-bottom: 5px;
}


.event-teaser__date{
    background-color: #fff;
    color: var(--color-primary);
    border: 2px solid var(--color-primary);
    display: inline-block;
    font-size: calc(18rem/16);
    padding: calc(4rem/16) calc(12rem/16);
    margin-top: calc(10rem/16);
}

.event-teaser__more-dates{
    font-size: calc(15.5rem/16);

    @media screen and (max-width: 767px){
       display: inline-block;
        margin-left: 10px;
    }
}

.event-teaser.corner-shadow:before {
    transform: skew(-0.5deg,-0.5deg);
}

.event-teaser.corner-shadow:after {
    transform: skew(0.5deg,0.5deg);
    left: auto;
    right: 8px;
}