.loyalty-container{
    background: var(--color-primary);
    padding: calc(20rem/16);
    margin: calc(16rem/16) 0;
}
.loyalty-container a{
    color: #000;
    text-decoration: underline;
}
.loyalty-checkbox-text{
    display: block;
    max-height: 1.5em;
    overflow: hidden;
    position: relative;
}
.loyalty-checkbox-text--expanded{
    max-height: none;
}
