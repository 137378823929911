

.gallery-slider__item{
    margin-right: calc(10rem/16);
}
.gallery-slider__item__img{
    width: auto;
    height: calc(260rem/16);
}

.gallery-slider__item__lightbox{
    position: relative;
    display: inline-block;
    overflow: hidden;
}