.product-grid{
    position: relative;
}

.product-grid-quick-filter{
    @media screen and (max-width: 991px){
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        display: block;
        font-size: 0;
    }
}
.horizontal-scroll-container{
    position: relative;
}

.horizontal-scroll-container:after {
    pointer-events: none;
    content: "";
    position: absolute;
    right: calc(-1rem/16);
    top: 0;
    bottom: 0;
    width: calc(25rem/16);
    z-index: 1;
    background: hsla(0,0%,100%,.5);
    background: linear-gradient(90deg,hsla(0,0%,100%,0) 0,hsla(0,0%,100%,.85));
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00fafafa",endColorstr="#fafafa",GradientType=1);
}


.product-grid-quick-filter__item{
    background-color: #fff;
    color: var(--color-primary);
    border: calc(2rem/16) solid var(--color-primary);
    border-radius: calc(9rem/16);
    cursor: pointer;
    font-family: var(--font-handwriting);
    text-transform: uppercase;
    padding: 0 calc(10rem/16);
    font-size: calc(18rem/16);

    @media screen and (min-width: 992px){
        font-size: calc(17.5rem/16);
    }
}

.product-grid-quick-filter__item .icon{
    color: var(--color-dark-grey);
    font-size: calc(11rem/16);
    margin-left: 5px;
}

.product-grid-quick-filter li{
    margin-bottom: calc(8rem/16);
}

.product-grid__content{
    margin-top: calc(20rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(40rem/16);
    }
}

.product-grid__row + .product-grid__row {
    margin-top: calc(30rem/16);
}

.product-grid__content--two-col .product-teaser .btn-cart{
    width: calc(160rem/16);
    min-height: auto;
    font-size: calc(11rem/16);
    padding: calc(4rem/16) 0;
}

.product-grid__content--two-col .product-teaser .btn-cart>.icon{
    font-size: calc(12rem/16);
    vertical-align: calc(-2rem/16);
    margin-right: calc(5rem/16);
}

.product-grid__view{
    line-height: 1;
}

.product-grid__view__item{
    font-size: calc(28rem/16);
    line-height: 1;
    display: inline-block;
    position: relative;
}

.product-grid__view__item .custom-radio__input:checked ~ .custom-radio__icon:after {
    content: '';
    position: absolute;
    top: calc(-3rem/16);
    bottom: calc(-3rem/16);
    left: calc(-3rem/16);
    right: calc(-3rem/16);
    border: 1px solid var(--color-primary);
}

.product-grid__view__item:not(:last-child){
    margin-right: calc(3rem/16);
}

.product-grid__remove-input{
    background-color: #fff;
    border: 2px solid #000;
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    font-size: calc(13rem/16);
    border-radius: calc(10rem/16);
    height: calc(30rem/16);
    padding: calc(3rem/16) calc(10rem/16);
    display: none;
}

.product-grid__remove-input .icon{
    font-size: calc(11rem/16);
    margin-left: calc(5rem/16);
    vertical-align: calc(-1rem/16);
}

.product-grid__remove-input.show{
    display: block;
}
.product-grid__sidebar-links li.active {
    font-weight: bold;
}

.js-product-grid-quick-filter__template.js-product-grid-quick-filter__template {
    display: none;
}