.polaroid-teaser {
    position: relative;
    padding: calc(10rem/16);
    box-shadow: 5px 5px 10px 5px rgba(0,0,0,.15);
    background: url(/static/img/bg/pattern-papier.jpg) 0 0 repeat;
    border-radius:.25rem;
    flex: 1;
}
.polaroid-teaser--rings {
    margin-top:2rem;
}
.polaroid-teaser--rings:before {
    content:'';
    position: absolute;
    left: 2rem;
    top:-1.5rem;
    right:2rem;
    width:auto;
    height:calc(56rem/16);
    background: url(/static/img/misc/calendar-rings.png) 50% 0 no-repeat;
    background-size: contain;
    z-index: 5;

    @media screen and (max-width: 767px) {
        height:calc(62rem/16);
    }
}
@media (max-width: 767px) {
    .product-grid__content:not(.product-grid__content--two-col) .polaroid-teaser {
        margin-left: 1rem;
        margin-right: 1rem;
    }
    
}
.polaroid-teaser__content {
    padding-top: calc(7rem/16);
    padding-bottom: calc(7rem/16);
}
.polaroid-teaser__title {
    font-family: var(--font-default-bold);
    font-size: calc(22rem/16);
    line-height: 1;
    margin: .5rem 0;
    @media (min-width: 768px) and (max-width: 1199px) {
        font-size: calc(20rem/16);
    }
/*    @media screen and (min-width: 768px) {
        transform: translate3D(0,0,0);
        transition: transform 0.2s ease;
    }*/
}
/*.polaroid-teaser-col:hover .polaroid-teaser__title,
.slick-slide .polaroid-teaser-col:hover .polaroid-teaser__title {
    @media screen and (min-width: 768px) {
        transform: translate3D(0,2px,0);
    }
}*/
.polaroid-teaser__text:before {
    content: '';
    display: block;
    margin:.5rem 0;
    width: 100%;
    height:.5rem;
    background-image: url(/static/img/bg/black-dots-10px.svg);
    background-position: 0 50%;
    background-repeat:repeat-x;
}

.polaroid-teaser-row {
    overflow: hidden;
    transform: translateZ(0);
    padding-bottom: calc(12rem/16);
    @media (min-width: 768px) {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

@media screen and (max-width: 767px) {
    .polaroid-teaser-col {
        margin-top: calc(15rem/16);
        max-width: calc(360rem/16);
    }

    .slick-slide .polaroid-teaser-col {
        max-width: none;
    }

}

/* rotations in row */
.polaroid-teaser-col,
.slick-slide .polaroid-teaser-col {
    display: flex !important;
    @media screen and (min-width: 768px) {
        transform: translate3D(0,0,0);
        transition: transform 0.2s ease;
    }
}
.polaroid-teaser-col:hover,
.slick-slide .polaroid-teaser-col:hover {
    @media screen and (min-width: 768px) {
        transform: translate3D(0,-5px,0);
    }
}

.polaroid-slider .polaroid-teaser-col {
    padding: 20px 12px;
}

/* make teaser 100% high */
@media screen and (min-width: 768px) {
    .polaroid-slider .slick-track {
        display: flex !important;
    }
    .polaroid-slider .slick-slide  {
        height: inherit !important;
    }
    .polaroid-slider .slick-slide > div {
        height:100%;
    }
    .polaroid-slider .polaroid-teaser-col {
        height:100%;
    }
}

.rotate-2deg {
    transform: rotate(-2deg);
}

/* teaser in two col grid mobile */
@media screen and (max-width: 767px){
    .product-grid__content--two-col .polaroid-teaser__title {
        font-size: 1rem;
    }
}

.polaroid-teaser__remove-btn {
    position: absolute;
    z-index: 1;
    right: 15px;
    top: 15px;
    color: white;
    text-shadow: 0 0 4px rgba(0,0,0,.7);
}