.toc,
.skip-links {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
}
.toc a:focus,
.skip-links a:focus {
    display: inline-block;
    text-transform: capitalize;
    color:#fff;
    text-decoration: none;
    background: var(--color-primary);
    padding:.5rem 1.25rem;
}