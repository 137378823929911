.random-image {
    position: fixed;
    z-index: 9; /* 1 lower than nav */
    cursor: pointer;
    max-width: calc(120rem/16);
    min-height: calc(80rem/16);
    width: calc(100rem/16);
    @media (min-width: 768px) {
        max-width: calc(200rem/16);
        min-height: calc(120rem/16);
        width: calc(200rem/16);
    }
}