.number-spinner {
    display: inline-flex;
    overflow: hidden;
    background-image: url(/static/img/btn/btn-menge.png);
    background-repeat: no-repeat;
    width: 177px;
    height: 52px;
    background-position: 0 0;
    font-family: var(--font-default-bold);
    color: black;
}
.number-spinner__button {
    background: transparent;
    width: 53px;
    font-size: 31px;
    line-height: 1;
    color:var(--color-text-default);
}
.number-spinner__input,
.input-group .number-spinner__input {
    width: 65px;
    min-width: 0;
    flex: 1 0 65px;
    text-align: center;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
}
.number-spinner__input::-webkit-inner-spin-button,
.number-spinner__input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.number-spinner .number-spinner__input {
    background: transparent;
    margin-left: 3px;
    margin-right: 3px;
}