.marker-cluster__count {
    position: absolute;
    top: calc(2rem / 16);
    left: 50%;
    transform: translateX(-50%);
    font-size: calc(18rem / 16);
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
    box-shadow: none;
    border-radius: 0;
}
.leaflet-container a.leaflet-popup-close-button {
    padding:5px 5px 0 0;
    width: 30px;
    height: 30px;
    font: 24px/20px Tahoma,Verdana,sans-serif;
}

.storelocator {
    position: relative;
    z-index: 0;
}

.leaflet-pane {
    z-index: 1;
}

.map-infowindow {
    font-size: calc(13rem/16);
}

.map-infowindow__title {
    font-size: calc(16rem/16);
}

.map-infowindow__loading {
    min-height: 40px;
    min-width: 140px;
    position: relative;
}

.map-infowindow__loading .loading-spinner__dots {
    top: 40%;
}

.map--naked-labels .leaflet-tooltip {
    background: transparent;
    border: none;
    box-shadow: none;
}

.map--naked-labels .leaflet-tooltip:before {
    display: none;
}

.map__sonnentor-label {
    color: var(--color-orange);
    text-transform: uppercase;
    font-family: var(--font-black);
    font-size: 14px;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

/* anbauprojekte */
.agricultural-map .leaflet-popup-tip-container {
    width: 1px;
    height: 40px;
    position: absolute;
    left: 50%;
    margin-left: -2px;
    overflow: hidden;
    pointer-events: none;
    border-left:4px dashed #000;
}
.agricultural-map .leaflet-popup-tip {
    display: none;
}
.agricultural-infowindow {
    text-align: center;
    padding:.5rem 0;
}
.agricultural-infowindow__title {
    font-size: calc(18rem/16);
    text-transform: uppercase;
    margin-bottom: .625rem;
    @media screen and (max-width: 767px){
        margin-bottom: .375rem;
    }
}
.agricultural-infowindow__text {
    font-size: calc(13rem/16);
    margin-bottom: 1rem;
    @media screen and (max-width: 767px){
        margin-bottom: .375rem;
    }
}
.agricultural-infowindow__img {
    width:3rem;
    height:3rem;
    margin:0 .375rem .375rem .375rem;

    @media screen and (max-width: 767px){
        width:2.5rem;
        height:2.5rem;
        margin:0 .25rem .25rem .25rem;
    }
}