.content-video video{
  background: #fff;
}

.vjs-default-skin .vjs-big-play-button {
  border-radius: 50%;
  width: calc(50rem/16);
  height:calc(50rem/16);
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: #fff;
  border-color: transparent;
  color: var(--color-default);

  @media screen and (min-width: 768px){
    width: calc(96rem/16);
    height:calc(96rem/16);
  }
}

.vjs-big-play-button .vjs-icon-placeholder {
  position: absolute;
  top: 50%;
  left: 53%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  height: calc(43rem / 16);
  width: calc(50rem / 16);
  font-size: calc(18rem / 16);

  @media screen and (min-width: 768px){
    font-size: calc(28rem / 16);
  }
}

.video-js .vjs-big-play-button:focus,
.video-js:hover .vjs-big-play-button {
  background-color: var(--color-primary);
  opacity: 0.8;
  color: #fff;
}

.content-video .video-js .vjs-big-play-button .vjs-icon-placeholder:before{
  content: var(--icon-play);
  font-family: iconfont;
}

.youtube-play-button {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 68px;
    height: 48px;
    transform: translate(-50%, -50%);
    transition: opacity .25s cubic-bezier(0,0,0.2,1);
    z-index: 1;
}

.youtube-play-button__bg {
    transition: fill .1s cubic-bezier(0.4,0,1,1),fill-opacity .1s cubic-bezier(0.4,0,1,1);
    fill: #212121;
    fill-opacity: .8;
}

.youtube-play-button-container {
    background: var(--color-grey);
}

.youtube-play-button-container:hover .youtube-play-button__bg {
    transition: fill .1s cubic-bezier(0,0,0.2,1),fill-opacity .1s cubic-bezier(0,0,0.2,1);
    fill: #f00;
    fill-opacity: 1;
}