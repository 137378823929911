.form-errors {
    color: var(--color-danger);
}
.form-errors:empty {
    display: none;
}

.generic-parsley-error {
    text-align: right;
    color: var(--color-danger);
    font-size:1rem;
}