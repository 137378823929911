.recipe__preparation{
    counter-reset: item;
}
.preparation-steps>li{
    counter-increment: item;
    position: relative;
    margin-bottom: calc(50rem/16);
    overflow: hidden;
}

.preparation-steps>li:before {
    content: counter(item);
    float: left;
    padding-bottom: 20px;

    font-size: calc(112rem/16);
    line-height: 0.8;
    font-family: var(--font-default-bold);
    color: var(--color-turquoise);
}

.preparation-steps__item {
    margin-left: 80px;
}