.cart-summary__title {
    text-transform: uppercase;
    opacity: 0.6;
    font-size: calc(13rem/16);
}

.cart-summary--total .cart-summary__title {
    font-size: calc(15rem/16);
    opacity: 1;

    @media screen and (max-width: 767px) {
        line-height:1;
        font-size: calc(14rem/16);
    }
}

.cart-summary__value {
    font-size: calc(15rem/16);
}

.cart-summary--total .cart-summary__value {
    font-size: calc(21rem/16);

    @media screen and (max-width: 767px) {
        line-height:1;
        font-size: calc(19rem/16);
    }
}

.cart-summary--top-border {
    border-top: 1px solid var(--color-gray-border);
    padding-top: 15px;
    margin-top: 5px;
}