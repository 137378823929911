.top-navbar{
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-size: calc(12rem/16);
    height: calc(38rem/16);
    display: flex;
    align-items: center;
    @media (min-width: 768px) {
        font-size: calc(14rem/16);
    }
    @media (min-width: 1440px) {
        font-size: 1rem;
    }
}

.top-navbar ul{
    display: flex;
    align-items: center;
    justify-content: center;
}

.top-navbar__item{
    display: flex;
    align-items: center;
    padding: calc(7rem/16) calc(8rem/16);
    line-height: 1.2;
    @media (min-width: 768px) {
        font-size: calc(7rem/16) calc(22rem/16);
    }
    @media (min-width: 1440px) {
        padding: calc(7rem/16) calc(32rem/16);
    }

}

.top-navbar__item .icon{
    font-size: calc(16rem/16);
    margin-right: calc(8rem/16);
    @media (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}

.top-navbar__item .icon.icon-cross-out{
    font-size: 15px;
}

.top-navbar__item .icon.icon-plant-2{
    font-size: 24px;
}