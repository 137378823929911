.configurator-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.configurator-items.selection-complete .configurator-item:not(.selected) {
    opacity: .5;
}

.configurator-item {
    padding: 1.5rem .75rem .5rem;
    font-size: 1rem;
    max-width: 300px;
    min-height: 350px;
    flex: 1 0 300px;
    margin-bottom: 5px;
    border: 5px solid transparent;
    cursor: pointer;
}

.configurator-item.selected {
    background: var(--color-light-grey);
    border: 5px solid var(--color-orange-pastel);
}
.configurator-item.selected .visible-if-selected {
    visibility: visible;
}

.configurator-item .visible-if-selected,
.configurator-item.selected .hidden-if-selected {
    visibility: hidden;
}

.configurator-item__img {
    max-width: 190px;
    border-radius: 100%;
    border: 5px solid var(--color-orange);
    margin-bottom: 1rem;
}


.configurator-item__body {
    position: relative;
}

.configurator-item__selected-text {
    position: absolute;
    top: 30px;
    width: 100%;
    font-size: calc(24rem/16);
}
.configurator-item .configurator-item__add-btn {
    padding-top: 15px;
    position: absolute;
    transition: transform 0.6s, opacity 0.6s;
    opacity: 0;
    top: 30px;
    width: 100%;
}

.configurator-item__text {
    transition: transform 0.6s, opacity 0.6s;
    transition-delay: 0.2s;
}


@media (min-width: 992px) {
    .configurator-items:not(.selection-complete) .configurator-item:hover .configurator-item__add-btn {
        opacity: 1;
        transform: translateY(-30px);
        transition-delay: 0.2s;
    }

    .configurator-items:not(.selection-complete) .configurator-item:hover .configurator-item__text {
        opacity: 0;
        transform: translateY(-30px);
        transition-delay: 0s;
    }

}