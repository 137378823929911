.promotion-slide__content{
    width: 100%;
    max-width: calc(668rem/16);
    margin: 0 auto;
}

.promotion-slide__collapse__hl{
    margin-top: calc(40rem/16);
    
    @media screen and (min-width: 768px){
       margin-top: calc(100rem/16);
    }
}

.promotion-slide__bg{
    width: calc(180rem/16);
    height: calc(140rem/16);
    background-size: contain;
    background-repeat: no-repeat;

    @media screen and (min-width: 1200px){
        width: calc(257rem/16);
        height: calc(200rem/16);
    }
    
    @media screen and (max-width: 1200px){
       opacity: 0;
    }
    
    @media screen and (max-width: 767px){
       display: none;
    }
}

.promotion-slide__bg.is-left{
    float: left;
}

.promotion-slide__bg.is-right{
    float: right;
    transform: scaleX(-1);
}

.promotion-slide__bg-top {
    @media screen and (min-width: 768px) and (max-width: 1200px){
        background-image: url(/static/img/bg/promotion-slide-bg-mobile.png);
        background-size: calc(571rem/16) calc(87rem/16);
        background-repeat: no-repeat;
        background-position: center;
        height: calc(87rem/16);
        width: 100%;
    }

}

.promotion-slide .default-heading__text{

    @media screen and (min-width: 1200px){
        margin-bottom: calc(25rem/16);
    }
    @media screen and (max-width: 1200px){
        border-bottom: calc(3rem/16) solid var(--color-default);
        padding-bottom: calc(8rem/16);
        font-size: calc(27rem/16);
    }

}

.promotion-slide__text{
    width: 80%;
    margin: 0 auto;
}