.toggle-switch__switch {
    position: relative;
    display: inline-flex;
    /* space for label */
    margin-right: .25rem;

    width: 60px;
    height: 20px;
    background: var(--color-light-grey);
    border-radius: 30px;
    box-shadow: inset 0 0 1px rgba(0,0,0,0.25);
    border: 1px solid #BEC2C6;

    font-size: 12px;
    transition: background-color 0.3s;
    @media (max-width: 767px) {
        width: 75px;
        height: 25px;
    }
}

.toggle-switch__option {
    width: 33.33%;
    cursor: pointer;
    text-align: center;
}

.toggle-switch__option__handle {
    position: relative;
    width: 21px;
    height: 21px;
    top: -1px;

    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
        width: 26px;
        height: 26px;
    }
}

.toggle-switch__input:checked + .toggle-switch__option__handle {
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 2px rgba(0,0,0,.5);
}

.toggle-switch__option--red {
    color: var(--color-danger);
}

.toggle-switch__option--red .toggle-switch__input:checked + .toggle-switch__option__handle {
    color: white;
    background: var(--color-danger);
}

.toggle-switch__option--green {
    color: var(--color-success);
}

.toggle-switch__option--green .toggle-switch__input:checked + .toggle-switch__option__handle {
    color: white;
    background: var(--color-success);
}

/* sr-only radio */
.toggle-switch__input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.toggle-switch__option .icon-check {
    margin-top: -2px;
}