.price-badge{
    position: relative;
    max-height: calc(80rem/16);
    z-index: 1;
}

.polaroid-teaser .price-badge {
    position: absolute;
    right: calc(-8rem/16);
    top: 7px;
    transform: rotate(5deg);
}


.price-badge__circle{
    font-size: calc(80rem/16);
    color: #fff;
    text-shadow: 0 0 6px rgba(0,0,0,.5);
    @media screen and (min-width: 1200px){
        font-size: calc(88rem/16);
    }
}

.price-badge__text{
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    line-height: calc(29/20);
    text-align: center;

    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translateX(-50%) translateY(-50%);
}

/* teaser in two col grid mobile */
@media screen and (max-width: 767px){
    .product-grid__content--two-col .price-badge {
        max-height: calc(64rem/16);
    }

    .product-grid__content--two-col .price-badge__circle{
        font-size: calc(64rem/16);
    }

}