.btn{
    font-size: calc(13rem/16);
    line-height: calc(23/13);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    position: relative;
    border-radius: 0;
    cursor: pointer;
}
.btn.btn-default{
    background-color: transparent;
    color: #000;
    border: none;
}

.btn.btn-default:before,
.btn.btn-default:after{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: calc(4rem/16);
}

.btn.btn-default:before{
    top: 0;
    background-image: url(/static/img/btn/linie-oben.svg);
    background-repeat: repeat-x;
    transition: transform 0.7s;
}

.btn.btn-default:after{
    bottom: 0;
    background-image: url(/static/img/btn/linie-unten.svg);
    background-repeat: repeat-x;
    transition: transform 0.7s;
    transition-delay: .1s;
}

.btn.btn-default:not(.disabled):hover:before {
    transform: rotateY(180deg);
}

.btn.btn-default:not(.disabled):hover:after {
    transform: rotateY(180deg);
}



.btn.btn-default--large{
    font-family: var(--font-handwriting);
    font-size: calc(24rem/16);
    padding-left:.5rem;
    padding-right:.5rem;
    line-height:1.2;
    text-transform: uppercase;
}
.btn.btn-default--large:before{
    display: none;
}
.btn.btn-default--large:after{
    bottom:-.25rem;
    background-image: url(/static/img/nav-linie.svg);
    height: calc(6rem/16);
    background-size: cover;
}



.btn.btn-dark{
    background-color: #333;
    background-image: url(/static/img/bg/kreidetafel.jpg);
    color: #fff;
    padding: calc(2rem/16) calc(10rem/16);
}

.btn.btn-collapse{
    color:inherit;
    font-size: calc(13rem/16);
    background-color: transparent;
    padding: calc(5rem/16);
}

.btn.btn-collapse:before,
.btn.btn-collapse:after{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: calc(8rem/16);
    background-repeat: no-repeat;
    margin: 0 auto;
}

.btn.btn-collapse:before{
    top: -3px;
    background-image: url(/static/img/btn/pfeil-oben.svg);
    background-position: center 0;
}

.btn.btn-collapse.collapsed:before{
    top: 0;
    background-image: url(/static/img/btn/linie-oben.svg);
    background-position: center 0;
}

.btn.btn-collapse:after{
    bottom: calc(-3rem/16);
    background-image: url(/static/img/btn/linie-unten.svg);
    height: calc(8rem/16);
}

.btn.btn-collapse.collapsed:after{
    background-image: url(/static/img/btn/pfeil-unten.svg);
    background-position: center bottom;
}

.btn-no-styling{
    border: none;
    background: none;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.btn-cart{
    background-image: url('/static/img/bg/bg-cart-btn.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    color: #fff;
    padding: calc(2rem/16) calc(20rem/16);
    border: none;
    background-size: 100% 100%;
    box-shadow: none;
    /*width: calc(360rem/16);*/
    max-width: 100%;
    min-height: calc(40rem/16);
    font-size: calc(20rem/16);
    
    @media screen and (min-width: 768px){
        min-height: calc(50rem/16);
    }
}

@media (min-width: 768px) {
    .btn-cart:hover,
    .btn-cart:focus {
        color: var(--color-orange);
    }
}
.product-information__cart .btn-cart {
    @media screen and (max-width: 767px){
        height: calc(57rem/16);
    }
}


.btn-cart.btn-sm{
    @media screen and (min-width: 768px){
        width: calc(160rem/16);
        min-height: auto;
        font-size: calc(11rem/16);
        padding: calc(2rem/16) calc(15rem/16);
    }

}

.btn-cart>.icon{
    font-size: calc(23rem/16);
    vertical-align: calc(-4rem/16);
    margin-right: 10px;
}

.btn-cart.btn-sm>.icon{
    @media screen and (min-width: 768px){
        font-size: calc(12rem/16);
        vertical-align: calc(-2rem/16);
        margin-right: calc(5rem/16);
    }

}

.btn.btn-lg{
    font-size: calc(18rem/16);
    line-height: calc(18/16);
    padding: .5rem 1.875rem;
    border-radius: 0;
    @media (min-width: 768px) {
        font-size: calc(20rem/16);
        line-height: calc(20/16);
        padding: .75rem 3.5rem;
    }
}

.btn-wishlist--bordered {
    color:var(--color-text-default);
    background-image: url('/static/img/btn/button-merkliste.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    padding: calc(15rem/16);
    border: none;
    box-shadow: none;
    width: calc(58rem/16);
    height: calc(51rem/16);
    font-size: calc(18rem/16);
}

.btn-mail {
    padding: calc(13rem/16) calc(15rem/16) calc(15rem/16) calc(15rem/16);
    background: #e5bf31;
    color: #ffffff;
    width: calc(50rem/16);
    height: calc(50rem/16);
    font-size: calc(16rem/16);
}

.date-input__btn {
    font-size: 1rem;
}
.btn-underline{
    font-family: var(--font-default);
    background: none;
    border: none;
    font-size: calc(17.5rem/16);
    cursor: pointer;
    padding: 0!important;
    line-height: 1;
    text-decoration: underline;
    display: inline-block;
    transition: color 0.3s;
    text-transform: none;
}