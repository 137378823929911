@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1743502718998/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1743502718998/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1743502718998/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-aehre:before { content: "\EA01" }
.icon-angebote:before { content: "\EA02" }
.icon-arrow-down:before { content: "\EA03" }
.icon-arrow-left:before { content: "\EA04" }
.icon-arrow-right:before { content: "\EA05" }
.icon-arrow-up:before { content: "\EA06" }
.icon-badge-circle:before { content: "\EA07" }
.icon-bath:before { content: "\EA08" }
.icon-bio:before { content: "\EA09" }
.icon-calendar:before { content: "\EA0A" }
.icon-chili-filled:before { content: "\EA0B" }
.icon-chili-kontur:before { content: "\EA0C" }
.icon-chili:before { content: "\EA0D" }
.icon-circle-filled:before { content: "\EA0E" }
.icon-click-collect-pin:before { content: "\EA0F" }
.icon-close:before { content: "\EA10" }
.icon-co2-neutral:before { content: "\EA11" }
.icon-coffee:before { content: "\EA12" }
.icon-cross-out:before { content: "\EA13" }
.icon-cup:before { content: "\EA14" }
.icon-delivery-time:before { content: "\EA15" }
.icon-drop:before { content: "\EA16" }
.icon-elements:before { content: "\EA17" }
.icon-erdhaufen:before { content: "\EA18" }
.icon-facebook:before { content: "\EA19" }
.icon-fan:before { content: "\EA1A" }
.icon-fill-in:before { content: "\EA1B" }
.icon-geschenk:before { content: "\EA1C" }
.icon-glutenfrei:before { content: "\EA1D" }
.icon-grid-two-col:before { content: "\EA1E" }
.icon-hand-and-leaves:before { content: "\EA1F" }
.icon-hand-pick:before { content: "\EA20" }
.icon-hand-shows:before { content: "\EA21" }
.icon-handshake:before { content: "\EA22" }
.icon-heat-2:before { content: "\EA23" }
.icon-heat:before { content: "\EA24" }
.icon-hildegard:before { content: "\EA25" }
.icon-honey:before { content: "\EA26" }
.icon-info:before { content: "\EA27" }
.icon-instagram:before { content: "\EA28" }
.icon-kinder:before { content: "\EA29" }
.icon-linkedin:before { content: "\EA2A" }
.icon-mail:before { content: "\EA2B" }
.icon-merkliste-filled:before { content: "\EA2C" }
.icon-merkliste:before { content: "\EA2D" }
.icon-neuheiten:before { content: "\EA2E" }
.icon-neuheiten_alt:before { content: "\EA2F" }
.icon-oeko-packaging-inverted:before { content: "\EA30" }
.icon-oeko-packaging:before { content: "\EA31" }
.icon-packing-inverted:before { content: "\EA32" }
.icon-packing:before { content: "\EA33" }
.icon-palm:before { content: "\EA34" }
.icon-pfeil-27-short:before { content: "\EA35" }
.icon-pfeil-27:before { content: "\EA36" }
.icon-pfeile_kreis:before { content: "\EA37" }
.icon-pinterest:before { content: "\EA38" }
.icon-plant-1:before { content: "\EA39" }
.icon-plant-2:before { content: "\EA3A" }
.icon-plant-3:before { content: "\EA3B" }
.icon-play:before { content: "\EA3C" }
.icon-pot-fill-in:before { content: "\EA3D" }
.icon-pot-full:before { content: "\EA3E" }
.icon-pot-stir:before { content: "\EA3F" }
.icon-pot:before { content: "\EA40" }
.icon-preparation-time-10min:before { content: "\EA41" }
.icon-preparation-time-15min:before { content: "\EA42" }
.icon-preparation-time-30min:before { content: "\EA43" }
.icon-preparation-time-3min:before { content: "\EA44" }
.icon-preparation-time-50min:before { content: "\EA45" }
.icon-preparation-time-5min:before { content: "\EA46" }
.icon-preparation-time-7min:before { content: "\EA47" }
.icon-preparation-time-ohne:before { content: "\EA48" }
.icon-prislusenstvi:before { content: "\EA49" }
.icon-raeuchern:before { content: "\EA4A" }
.icon-rechteck-filled:before { content: "\EA4B" }
.icon-search:before { content: "\EA4C" }
.icon-sensoriktasse:before { content: "\EA4D" }
.icon-share:before { content: "\EA4E" }
.icon-sirup:before { content: "\EA4F" }
.icon-slider-arrow-left:before { content: "\EA50" }
.icon-slider-arrow-right:before { content: "\EA51" }
.icon-steeping-time-10min:before { content: "\EA52" }
.icon-steeping-time-3min:before { content: "\EA53" }
.icon-steeping-time-5min:before { content: "\EA54" }
.icon-success:before { content: "\EA55" }
.icon-sun:before { content: "\EA56" }
.icon-tea:before { content: "\EA57" }
.icon-teabag-big:before { content: "\EA58" }
.icon-teabag:before { content: "\EA59" }
.icon-teacup:before { content: "\EA5A" }
.icon-teapot-with-bag:before { content: "\EA5B" }
.icon-teapot:before { content: "\EA5C" }
.icon-twitter:before { content: "\EA5D" }
.icon-user:before { content: "\EA5E" }
.icon-usp-NR1:before { content: "\EA5F" }
.icon-usp-fast-delivery:before { content: "\EA60" }
.icon-usp-warenkorb-leer:before { content: "\EA61" }
.icon-usp-warenkorb-voll:before { content: "\EA62" }
.icon-vegan:before { content: "\EA63" }
.icon-verpackung-inverted:before { content: "\EA64" }
.icon-verpackung:before { content: "\EA65" }
.icon-warenkorb:before { content: "\EA66" }
.icon-watertemperature:before { content: "\EA67" }
.icon-whatsapp:before { content: "\EA68" }
.icon-xing:before { content: "\EA69" }
.icon-youtube:before { content: "\EA6A" }


:root {
--icon-aehre: "\EA01";
    --icon-angebote: "\EA02";
    --icon-arrow-down: "\EA03";
    --icon-arrow-left: "\EA04";
    --icon-arrow-right: "\EA05";
    --icon-arrow-up: "\EA06";
    --icon-badge-circle: "\EA07";
    --icon-bath: "\EA08";
    --icon-bio: "\EA09";
    --icon-calendar: "\EA0A";
    --icon-chili-filled: "\EA0B";
    --icon-chili-kontur: "\EA0C";
    --icon-chili: "\EA0D";
    --icon-circle-filled: "\EA0E";
    --icon-click-collect-pin: "\EA0F";
    --icon-close: "\EA10";
    --icon-co2-neutral: "\EA11";
    --icon-coffee: "\EA12";
    --icon-cross-out: "\EA13";
    --icon-cup: "\EA14";
    --icon-delivery-time: "\EA15";
    --icon-drop: "\EA16";
    --icon-elements: "\EA17";
    --icon-erdhaufen: "\EA18";
    --icon-facebook: "\EA19";
    --icon-fan: "\EA1A";
    --icon-fill-in: "\EA1B";
    --icon-geschenk: "\EA1C";
    --icon-glutenfrei: "\EA1D";
    --icon-grid-two-col: "\EA1E";
    --icon-hand-and-leaves: "\EA1F";
    --icon-hand-pick: "\EA20";
    --icon-hand-shows: "\EA21";
    --icon-handshake: "\EA22";
    --icon-heat-2: "\EA23";
    --icon-heat: "\EA24";
    --icon-hildegard: "\EA25";
    --icon-honey: "\EA26";
    --icon-info: "\EA27";
    --icon-instagram: "\EA28";
    --icon-kinder: "\EA29";
    --icon-linkedin: "\EA2A";
    --icon-mail: "\EA2B";
    --icon-merkliste-filled: "\EA2C";
    --icon-merkliste: "\EA2D";
    --icon-neuheiten: "\EA2E";
    --icon-neuheiten_alt: "\EA2F";
    --icon-oeko-packaging-inverted: "\EA30";
    --icon-oeko-packaging: "\EA31";
    --icon-packing-inverted: "\EA32";
    --icon-packing: "\EA33";
    --icon-palm: "\EA34";
    --icon-pfeil-27-short: "\EA35";
    --icon-pfeil-27: "\EA36";
    --icon-pfeile_kreis: "\EA37";
    --icon-pinterest: "\EA38";
    --icon-plant-1: "\EA39";
    --icon-plant-2: "\EA3A";
    --icon-plant-3: "\EA3B";
    --icon-play: "\EA3C";
    --icon-pot-fill-in: "\EA3D";
    --icon-pot-full: "\EA3E";
    --icon-pot-stir: "\EA3F";
    --icon-pot: "\EA40";
    --icon-preparation-time-10min: "\EA41";
    --icon-preparation-time-15min: "\EA42";
    --icon-preparation-time-30min: "\EA43";
    --icon-preparation-time-3min: "\EA44";
    --icon-preparation-time-50min: "\EA45";
    --icon-preparation-time-5min: "\EA46";
    --icon-preparation-time-7min: "\EA47";
    --icon-preparation-time-ohne: "\EA48";
    --icon-prislusenstvi: "\EA49";
    --icon-raeuchern: "\EA4A";
    --icon-rechteck-filled: "\EA4B";
    --icon-search: "\EA4C";
    --icon-sensoriktasse: "\EA4D";
    --icon-share: "\EA4E";
    --icon-sirup: "\EA4F";
    --icon-slider-arrow-left: "\EA50";
    --icon-slider-arrow-right: "\EA51";
    --icon-steeping-time-10min: "\EA52";
    --icon-steeping-time-3min: "\EA53";
    --icon-steeping-time-5min: "\EA54";
    --icon-success: "\EA55";
    --icon-sun: "\EA56";
    --icon-tea: "\EA57";
    --icon-teabag-big: "\EA58";
    --icon-teabag: "\EA59";
    --icon-teacup: "\EA5A";
    --icon-teapot-with-bag: "\EA5B";
    --icon-teapot: "\EA5C";
    --icon-twitter: "\EA5D";
    --icon-user: "\EA5E";
    --icon-usp-NR1: "\EA5F";
    --icon-usp-fast-delivery: "\EA60";
    --icon-usp-warenkorb-leer: "\EA61";
    --icon-usp-warenkorb-voll: "\EA62";
    --icon-vegan: "\EA63";
    --icon-verpackung-inverted: "\EA64";
    --icon-verpackung: "\EA65";
    --icon-warenkorb: "\EA66";
    --icon-watertemperature: "\EA67";
    --icon-whatsapp: "\EA68";
    --icon-xing: "\EA69";
    --icon-youtube: "\EA6A";
    
}