.select{
    position: relative;
}

.form-inline .form-group.select {
    flex-direction: column;
    align-items: flex-start;
}

.select>select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding-right: calc(40rem/16);
    cursor: pointer;
    background: transparent;
}
.select select::-ms-expand {
    display: none;
}

.select:before {
    font-family: "iconfont";
    content: var(--icon-arrow-down);
    speak: none;
    position: absolute;
    bottom: 0;
    top: 50%;
    transform: translateY(-50%);
    right: calc(15rem/16);
    font-size: calc(8rem/16);
    pointer-events: none;
    display: flex;
    align-items: center;
    color: var(--color-dark-grey);
}

.select.form-group--standard:before {
    top: 17px;
    bottom: auto;
    transform: none;
}

.select.select--as-link select{
    color: var(--color-primary);
    border: none;
    padding-right: calc(20rem/16);
}

.select.select--as-link option{
    color: var(--color-default);
}

.select.select--as-link:before{
    right: calc(3rem/16);
}