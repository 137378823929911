.lightbox-img{
  position: relative;
  overflow: hidden;
}

.btn.btn-lightbox{
  content: '';
  position: absolute;
  right: calc(-18rem/16);
  top: calc(-22rem/16);
  width: calc(60rem/16);
  height: calc(60rem/16);
  border: 2px solid var(--color-primary);
  border-radius: 50%;
  opacity: 0;
  transform: scale3d(0.65,0.65,1);
  transform-origin: top right;
  transition: opacity 0.35s, transform 0.35s;
  background-color: var(--color-primary);
  display: block;
  padding: 0;
  color: #fff;
  z-index: 2;
}

.btn-lightbox .icon{
  position: relative;
  top: calc(11rem/16);
  left: calc(-5rem/16);
  font-size: calc(19rem/16);
}

.lightbox-img:hover .btn.btn-lightbox {
  opacity: 1;
  transform: scale3d(1,1,1);
}

.lg-actions .lg-next, .lg-actions .lg-prev{
  @media screen and (max-width: 767px){
     font-size: calc(16rem/16);
    padding: calc(5rem/16);
  }
}

.lg-actions .lg-next{
  @media screen and (max-width: 767px){
     right: calc(10rem/16);
  }
}

.lg-actions .lg-prev{
  @media screen and (max-width: 767px){
    left: calc(10rem/16);
  }
}

.lg-sub-html .btn-wrapper .btn:not(:last-child){
  @media screen and (max-width: 767px){
     margin-right: 0;
  }
}