body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default);
}

b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
}

h1, .h1 {
    font-size: calc(30rem/16);
    line-height: calc(39/26);
    font-family: var(--font-black);
}
h2, .h2 {
    font-size: calc(24rem/16);
    line-height: calc(31/28);
    font-family: var(--font-black);
}
h3, .h3 {
    font-size: calc(19rem/16);
    font-family: var(--font-black);
}
h4, .h4 {
    font-size: calc(17rem/16);
    font-family: var(--font-black);
}
h5, .h5 {
    font-size: calc(16rem/16);
    font-family: var(--font-black);
}
h6, .h6 {
    font-size: 1em;
}

@media screen and (min-width: 768px){
    h1, .h1 {
        font-size: calc(36rem/16);
    }
    h2, .h2 {
        font-size: calc(28rem/16);
    }
    h3, .h3 {
        font-size: calc(21rem/16);
    }
    h4, .h4 {
        font-size: calc(18rem/16);
    }
    h5, .h5 {
        font-size: calc(17rem/16);
    }
    h6, .h6 {
        font-size: 1em;
    }
}

.checkout-headline {
    font-size: calc(15rem/16);
    text-transform: uppercase;
    font-family: var(--font-black);
}
