.footer {
    margin-top: calc(40rem/16);
    overflow: hidden;
    position: relative;
    z-index: 25;
}
.footer-body {
    position: relative;
    background: #F4F2F3;
}
.footer-body:before {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    bottom: 100%;
    width: 100%;
    height: calc(10rem/16);
    background: url(/static/img/misc/overlay-footer-top.svg);
    background-size: 100% auto;

    @media screen and (max-width: 767px) {
        bottom: calc(100% - 5rem/16);
    }
}

.footer__questions-link {
    position: relative;
    font-size: calc(28rem/16);
    line-height: 1;

    @media screen and (max-width: 767px) {
        font-size: calc(18rem/16);
        line-height: 0.8;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        font-size: calc(26rem/16);
        line-height: 0.8;
    }
}
.footer__questions-link:after {
    content: '';
    position: absolute;
    top: calc(70rem/16);
    left: 50%;
    width: calc(50rem/16);
    height: calc(50rem/16);
    border-bottom-left-radius: calc(50rem/16);
    border-color: #000;
    border-width: calc(5rem/16);
    border-bottom-style: solid;
    border-left-style: solid;
    float: left;
    pointer-events: none;

    @media screen and (min-width: 768px) and (max-width: 1499px) {
        width: calc(24rem/16);
        height: calc(24rem/16);
        border-width: calc(3rem/16);
        top: calc(65rem/16);
        left: 75%;
    }

    @media screen and (max-width: 767px) {
        width: calc(24rem/16);
        height: calc(24rem/16);
        border-width: calc(3rem/16);
        top: calc(40rem/16);
        left: 80%;
        transform: rotate(-20deg);
    }
}
.footer__newsletter-heading {
    font-size: calc(28rem/16);
}
.footer__newsletter-button {
    position: relative;
    font-size: calc(22rem/16);
}
.footer__newsletter-input {
    position: relative;
}
.footer__newsletter-input:focus + .footer__newsletter-button {
    display: block;
}
.footer__newsletter-input label {
    width: 100%;
}
.footer__newsletter-input:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: calc(350rem/16);
    height: calc(5rem/16);
    background-image: url(/static/img/btn/linie-unten.svg);
    background-repeat: repeat-x;

    @media screen and (max-width: 767px) {
        width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: 65%;
    }
}
.footer__newsletter-input__input {
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    background: transparent;
    border: none;
    width: 50%;
    font-size: calc(22rem/16);
    
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        font-size: calc(18rem/16);
        width: 80%
    }
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}
.footer__newsletter-gdpr {
    margin:.5rem 0;
    font-size: calc(14rem/16);
}
.footer__newsletter-gdpr a {
    color:var(--color-primary);
}
.footer__newsletter-input__input:focus {
    outline: none;
}

.footer__newsletter-heading input[type=checkbox] {
    appearance: none;
    -webkit-appearance: none;
    display: none;
}

.accordion--footer {
    position: relative;
    width: 100%;
    font-family: var(--font-default);
}
.accordion--footer .card {
    background-color: #F4F2F3;
    border: none;
}
.accordion--footer .card__btn-title {
    text-transform: none;
    font-family: var(--font-default-bold);
    font-size: calc(20rem/16);
    color: var(--color-text-default);
    text-align: left;
    width: 100%;
}
.accordion--footer .card__btn-title:focus {
    outline: none;
}
.accordion--footer .card__btn-title .accordion--default__collapse {
    float: right;
}
.accordion--footer .card-header {
    border: none;
    background-color: #F4F2F3;
    padding: calc(8rem/16) calc(12rem/16);
}

.accordion--footer .card-header:after {
    @media screen and (max-width: 767px) {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: calc(5rem/16);
    background-image: url(/static/img/btn/linie-unten.svg);
    background-repeat: repeat-x;
    }
}
.accordion--footer:before {
    @media screen and (max-width: 767px) {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: calc(5rem/16);
        background-image: url(/static/img/btn/linie-unten.svg);
        background-repeat: repeat-x;
        z-index: 1;
    }
}

.footer__bottom {
    position: relative;
    
    @media screen and (max-width: 767px) {
        text-align: center;
    }
}
.footer__bottom .footer-item--divider {
    position: relative;
    margin-right: calc(20rem/16);
}
.footer__bottom .footer-item--divider:after {
    content: '|';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    margin-right: calc(-15rem/16);
}
.footer__bottom .footer-item--divider:last-child:after {
    display: none;
}

.footer-dropdown .dropdown-toggle:after {
    display: none;
}

.footer__contact-address {
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: 70%;
    }
}

/* question lady */
.footer__questions-image__text {
    @media screen and (min-width: 768px) and (max-width: 1499px) {
        position: absolute;
        left: 0;
        top: calc(20rem/16);
        text-align: left;
        line-height: 1;
    }
    @media screen and (max-width: 767px) {
        position: absolute;
        left: calc(50rem/16);
        top: calc(20rem/16);
        text-align: left;
        line-height: 1;
    }
}
.footer__questions-image {
    position: absolute;
    bottom: 0;
    right: calc(180rem/16);
    width: calc(250rem/16);

    @media screen and (max-width: 767px) {
        position: relative;
        width: 100%;
        right: inherit;
        text-align: center;
    }

    @media screen and (min-width: 768px) and (max-width: 1499px) {
        right: calc(128rem/16);
        width: calc(270rem/16);
    }
}
.footer__questions-image__img {
    max-height: calc(220rem/16);
    float: right;

    @media screen and (max-width: 767px) {
        max-height: calc(120rem/16);
        padding-left: calc(80rem/16);
        float: none;
    }
    @media screen and (min-width: 768px) and (max-width: 1499px) {
        max-height: calc(150rem/16);
    }
}

/* sitemap */
.footer__sitemap {
    position: relative;
    overflow:hidden;
}
.footer__sitemap-close {
    position: absolute;
    right: calc(20rem/16);
    top: calc(10rem/16);
}
/*.footer__sitemap-content-wrapper:before {
    content: '';
    position: absolute;
    bottom: calc(100% - 5rem/16);
    left: calc(50% - 5rem/16);
    box-sizing: border-box;
    height: calc(10rem/16);
    width: calc(10rem/16);
    border-style: solid;
    background-color: #333;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
    z-index: 1;
}*/
.footer__sitemap-content-wrapper {
    position: relative;
    background: #F4F2F3;
    display: block;

    @media screen and (max-width: 767px) {
        padding-top: calc(12rem/16);
    }
}
.footer__sitemap-content-wrapper.hidden {
    display: none;
}
.footer__sitemap-content {
    background: #fff;
    border: 1px solid var(--color-grey);
}
.footer__sitemap .footer__sitemap-link.btn {
    padding: calc(5rem/16) calc(20rem/16);
    font-size: calc(16rem/16);
    border-bottom: none;

    @media screen and (max-width: 767px) {
        width: 100%;
        border-bottom: 1px solid var(--color-dark);
    }
}
.footer__sitemap .footer__sitemap-link.btn:hover {
    color: #ffffff;

    @media screen and (max-width: 767px) {
        color: var(--color-dark);
        background: transparent;
    }
}
.footer__sitemap-content__title-item {
    margin-bottom: calc(20rem/16);
}
.footer__sitemap-content__item {
    margin-bottom: calc(5rem/16);
}
.footer__sitemap-content__heading {
    position: relative;
    padding-bottom: calc(5rem/16);
}
.footer__sitemap-content__heading:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: calc(5rem/16);
    background-image: url(/static/img/btn/linie-unten.svg);
    background-repeat: repeat-x;

    @media screen and (max-width: 767px) {
        width: calc(250rem/16);
    }
}

/* Icons */
.pre-footer__icons--fixed {
    position: relative;
    height: calc(60rem/16);
    margin: 0;
    display: block;
}
.footer__icons {
    position: relative;
    margin: 0;
}
.footer__icons-item {
    width: calc(40rem/16);
    height: auto;
}
.footer__icons-ssl {
    position: absolute;
    left: calc(30rem/16);
    @media screen and (max-width: 767px) {
        left: calc(10rem/16);
    }
}
.footer__icons-ssl-move-in {
    position: absolute;
    left: calc(-40rem/16);
    transition: left 1s ease-out;
}
.footer__icons-ssl-move-in.move-in {
    left: calc(30rem/16);
    @media screen and (max-width: 767px) {
        left: calc(10rem/16);
    }
}

.footer__icons-trusted-shops {
    position: absolute;
    right: calc(30rem/16);
    @media screen and (max-width: 767px) {
        right: calc(10rem/16);
    }
}


/* traktor */
.pre-footer__animation {
    display: block;
    position: relative;
    height: calc(60rem/16);
    margin: 0;
    transition: 5.0s;
    transition-delay: 1.0s;
    left: -10%;

    @media screen and (max-width: 767px) {
        height: calc(40rem/16);
        left: -30%;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        left: -20%;
    }
}
.pre-footer__animation.start-driving {
    left: 40%;

    @media screen and (max-width: 767px) {
        left: 20%;
    }

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        left: 30%;
    }
}
.footer__traktor {
    position: absolute;
    width: 100%;
    z-index: 1;
    font-size: 0;
}
.footer__traktor-item#traktor-rad {
    width: calc(33rem/16);
    height: auto;
    position: relative;
    left: calc(-73rem/16);
    top: calc(13rem/16);

    @media screen and (max-width: 767px) {
        width: calc(24rem/16);
        left: calc(-47rem/16);
        top: calc(8rem/16);
    }
}
.footer__traktor-item#traktor-1 {
    position: relative;
    width: calc(78rem/16);
    height: auto;
    margin-left: calc(-7rem/16);
    top: 2px;

    @media screen and (max-width: 767px) {
        width: calc(47rem/16);
    }
}
.footer__traktor-item#traktor-2 {
    position: relative;
    width: calc(88rem/16);
    height: auto;
    margin-left: calc(-3rem/16);

    @media screen and (max-width: 767px) {
        width: calc(54rem/16);
    }
}
.footer__traktor-item#traktor-3 {
    position: relative;
    width: calc(97rem/16);
    height: auto;
    margin-left: calc(-7rem/16);

    @media screen and (max-width: 767px) {
        width: calc(59rem/16);
    }
}
.footer__traktor-item#traktor-4 {
    position: relative;
    width: calc(86rem/16);
    height: auto;

    @media screen and (max-width: 767px) {
        width: calc(53rem/16);
    }
}

.footer__traktor-item#traktor-rad.start-driving {
    animation: roll 5s 0.5s 1 forwards;
    animation-timing-function: ease-in-out;
}
.footer__traktor-item#traktor-2.start-driving {
    animation: drive 1s 0.5s 4 forwards;
    animation-timing-function: ease-in-out;
}
.footer__traktor-item#traktor-3.start-driving {
    animation: drive 0.8s 0.5s 5 forwards;
    animation-timing-function: ease-in-out;
}
.footer__traktor-item#traktor-4.start-driving {
    animation: drive 0.5s 0.5s 8 forwards;
    animation-timing-function: ease-in-out;
}

@keyframes drive {
    0% {
        transform: rotate(0) translateY(0);
    }
    33% {
        transform: rotate(-1deg) translateY(-2px);
    }
    50% {
        transform: rotate(0) translateY(0);
    }
    66% {
        transform: rotate(1deg) translateY(-2px);
    }
    100% {
        transform: rotate(0) translateY(0);
    }
}
@keyframes roll {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(720deg);
    }
}

.footer-item__social-link .icon {
    vertical-align: -2px;
}

    /* language switch */
.footer__change-language-img {
    width: calc(20rem/16);
    height: auto;
    margin-right: calc(8rem/16);
}

/* mobile dropdown */
.accordion--footer .card__btn-title {
    position: relative;
}
.footer-toggle.icon {
    position: absolute;
    right: .3125rem;
    top: 50%;
    font-size: .5625rem;
    z-index: 25;
    padding: .625rem;
    transition: transform .2s;
    transform: translateY(-50%);
}
.accordion--footer .card__btn-title[aria-expanded="true"] .footer-toggle.icon{
    transform: translateY(-50%) scale(-1);
}