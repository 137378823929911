.text-icon-block {
    position: relative;
    padding-top: calc(25rem/16);
    padding-bottom: calc(25rem/16);
}
.content--has-line:before,
.animated-hr:before {
    content: '';
    position: absolute;
    width: 100%;
    height: calc(5rem/16);
    top: 0;
    left: 0;
    background-image: url(/static/img/btn/linie-oben.svg);
    background-position: 0 0;
    background-repeat: repeat-x;
}

.animated-hr {
    height: 7px;
    border: none;
    overflow: hidden;
    transform: translateX(-100%);
}

.animated-hr.animate-into-viewport--animate {
    height: 10px;
    overflow: hidden;
    transform: translateX(0);
    transition: transform 1.3s;
}

.animated-hr:before {
    transform: translateX(100%);
}

.animated-hr.animate-into-viewport--animate:before {
    transform: translateX(0);
    transition: transform 1.3s;
}

.text-icon-block__text {
    font-size: calc(19rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
    }
}


.text-icon-block__icons-wrapper {
    margin-top: calc(35rem/16);
}
@media screen and (max-width: 767px) {
    .text-icon-block__icons-wrapper > img {
        display: inline-block;
        width: calc(100rem / 16);
        height: calc(100rem / 16);
    }
}
.text-icon-block__icons {
    position: relative;
    padding-left: calc(20rem/16);
    padding-right: calc(20rem/16);
    height: calc(100rem/16);
    font-size: calc(55rem/16);

    @media screen and (max-width: 767px) {
        height: calc(70rem/16);
        font-size: calc(45rem/16);
        padding-left: calc(10rem/16);
        padding-right: calc(10rem/16);
    }
}
.text-icon-block__icons .icon {
    margin-top: calc(20rem/16);
}
.text-icon-block__icons:not(:last-child):after {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    height: 100%;
    width: calc(1rem/16);
    background-color: #000000;
}