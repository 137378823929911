.accordion--faq .card + .card{
    margin-top: calc(20rem/16);
}

.accordion--faq .card{
    background-color: var(--color-light-grey);
}

.accordion--faq .card-header{
    border-color: #ddd;
    border-bottom: none;
    background-color: var(--color-light-grey);
}

.accordion--faq .card,
.accordion--faq .card:first-of-type,
.accordion--faq .card:not(:first-of-type):not(:last-of-type){
    border-color: #ddd;
    border-bottom: 1px solid #ddd;
}

.accordion--faq .card-body{
    background-color: var(--color-light-grey);
}

/* accordion faq-item */
.accordion--faq-item .card,
.accordion--faq-item .card:first-of-type{
    border: none;
    background: transparent;
}
.accordion--faq-item .card-header{
    border: none;
    background-color: transparent;
    padding-bottom: 0;
}
.accordion--faq-item .card-body{
    padding-top: 0;
}

/* accordion icon */
.accordion--icon {
    position: relative;
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
}
.accordion--icon .card,
.accordion--icon .card:first-of-type{
    border: none;
    background: transparent;
    font-size: calc(19rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
    }
}
.accordion--icon .card-header{
    border: none;
    background-color: transparent;
    padding-bottom: 0;
    padding-top: 0;
}
.accordion--icon .card-body{
    padding-top: 0;
}
.accordion--icon .card-body__icons{
    font-size: calc(60rem/16);
}

/* accordion no styling */
.accordion--no-styling .card,
.accordion--no-styling .card:first-of-type{
    border: none;
    background: transparent;
}
.accordion--no-styling .card-header{
    border: none;
    background-color: transparent;
    padding-bottom: 0;
    padding-top: 0;
}
.accordion--no-styling .card-body{
    padding-top: 0;
}


.accordion .collapsed .accordion__show-less{
    display: none;
}

.accordion .collapsed .accordion__show-more{
    display: block;
}

.accordion .accordion__show-less{
    display: block;
}

.accordion  .accordion__show-more{
    display: none;
}

/* accordion default */


.accordion--default .card{
    border: none;
    border-top: 1px solid #ECEAEA;
    border-bottom: 1px solid #ECEAEA;
}

.accordion--default .card-header{
    padding: 0;
    background-color: #fff;
    border: none;
}

.accordion--default .card-header__title{
    font-family: var(--font-default-bold);
    color: var(--color-dark-grey);
    text-transform: uppercase;
    font-size: calc(14rem/16);
    height: calc(45rem/16);
}

.accordion--default .card-header__title a.active{
    color: var(--color-default);
}

.accordion--default .card-header__title a:hover{
    color: var(--color-default);
}

.accordion--default .card__btn{
    width: auto;
    text-align: left;
    padding: calc(18rem/16) calc(18rem/16);
    display: flex;
    align-items: center;

    @media screen and (min-width: 992px){
        font-size: calc(16rem/16);
    }
}

.accordion--default .card__btn.collapsed + a{
    color: var(--color-dark-grey);
}
.accordion--default .card__btn.collapsed + a:hover,
.accordion--default .card__btn.collapsed + a:focus{
    color: var(--color-default);
}

.accordion--default .card__btn + a{
    color: var(--color-default);
}

.accordion--default__icon {
    font-size: calc(32rem/16);
    margin-right: calc(10rem/16);
}

.accordion--default .card-body{
    padding: calc(3rem/16) 0 calc(30rem/16) calc(37rem/16);
    font-size: calc(16rem/16);
}

.accordion--default .card-body li+li{
    margin-top: calc(9rem/16);
}

.accordion--default .card-body a:hover{
    color: var(--color-primary-text);
}

.collapsed .accordion--default__collapse{
    transform: rotate(0deg);
}

.accordion--default__collapse{
    transform: rotate(180deg);
    color: var(--color-dark-grey);
    font-size: calc(9rem/16);
}

/** accordion filter */

.accordion--default.accordion--filter .card__btn{
    text-transform: none;
    font-family: var(--font-default);
    color: var(--color-default);
    font-size: calc(16rem/16);
}

.accordion--filter .card__btn-title{
    @media screen and (max-width: 767px){
       display: block;
        height: calc(45rem/16);
        width: 100%;
        text-align: left;
        padding-left: calc(15rem/16);
    }
}

.accordion--filter .accordion--default__collapse{
    @media screen and (max-width: 767px){
       padding: calc(18rem/16) calc(15rem/16);
    }
}

/* accordion primary*/
.accordion--primary .accordion__btn-title {
    display: flex;
    padding: .75rem;
    width: 100%;
    margin-top: .25rem;

    background: var(--color-orange-pastel);
    font-family: var(--font-default);
    text-transform: uppercase;
    text-align: left;
}

.accordion--primary .accordion__body {
    padding: .75rem;
}

.accordion--primary .accordion__icon {
    color: var(--color-primary);
    margin-right: .5rem;
    margin-top: .5rem;
    transition: transform .3s;
    font-size: calc(10rem/16);
}

.accordion--primary .collapsed .accordion__icon {
    transform: rotate(-90deg);
}

/* accordion hours*/
.accordion--hours .accordion__btn-title {
    display: flex;
    margin-top: calc(8rem/16);
    text-transform: uppercase;
    text-align: left;
    width: 100%;
}

.accordion--hours .accordion__icon {
    margin-right: .5rem;
    margin-top: .5rem;
    transition: transform .3s;
    font-size: calc(6rem/16);
}

.accordion--hours .collapsed .accordion__icon {
    transform: rotate(-90deg);
}

.accordion--hours .accordion__btn-title-text {
    border-bottom: 1px solid black;
    display: block;
}

.accordion--hours .collapsed .accordion__btn-title-text {
    border: none;
}