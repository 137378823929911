.download-teaser{
    font-size: calc(16rem/16);
    padding: calc(10rem/16);
    border: 1px solid var(--color-gray-border);
    overflow: hidden;
}

.download-teaser__title{
    border-bottom: calc(3rem/16) solid var(--color-default);
    display: inline-block;
    padding-bottom: calc(2rem/16);
    font-size: calc(23rem/16);
    margin-bottom: 1rem;
    
    @media screen and (max-width: 767px){
       margin-top: calc(10rem/16);
       margin-bottom: calc(10rem/16);
    }
}

.download-teaser--small .download-teaser__title {
    margin-bottom: 1.5rem;
}

.download-teaser__img{

    @media screen and (max-width: 767px){
        max-width: calc(200rem/16);
        width: 100%;
        margin: 0 auto;
    }
    
    @media screen and (min-width: 768px){
        width: calc(139rem/16);
    }

    @media screen and (min-width: 992px){
       width: calc(160rem/16);
    }
}

.download-teaser__hl{
    font-family: var(--font-default-bold);
    font-size: calc(14rem/16);
    text-transform: uppercase;
    margin-bottom: calc(10rem/16);
    border-bottom: calc(3rem/16) solid var(--color-default);
    padding-bottom: calc(3rem/16);
    display: inline-block;

    @media screen and (min-width: 768px){
        margin-bottom: calc(15rem/16);
    }

}