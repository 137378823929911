.voucher-container{
    width: 310px;
    height: 198px;
    position: relative;
    justify-self: center;
}

.voucher-info{
    position: absolute;
    top:0;
    left:0;
    width: 65%;
    height: 48%;
}

.voucher-code{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 65%;
    height: 52%;
}
.voucher-code__container{
    width: 100%;
    height: 100%;
    align-content: center;
    justify-items: center;
}
.voucher-code__code{
    width: calc(140rem/16);
    height: calc(60rem/16);
}

.voucher-for-you{
    position: absolute;
    top:0;
    right:0;
    width: 35%;
    height: 21%;
}
.voucher-sun{
    position: absolute;
    bottom:0;
    right:0;
    width: 35%;
    height: 79%;
}
.voucher-text{
    padding: calc(20rem/16) 0;
}