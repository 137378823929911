@media not print {
    .animate-into-viewport--fade-up.animate-into-viewport--init {
        transform: translateY(80px);
        opacity: 0;
    }

    .animate-into-viewport--fade-up.animate-into-viewport--animate {
        transform: translateY(0);
        opacity: 1;
        transition: 1.3s;
    }

    .animate-into-viewport--zoom-in {
        opacity: 0;
        transform: scale(0.5);
    }

    .animate-into-viewport--animate.animate-into-viewport--zoom-in {
        opacity: 1;
        transform: scale(1);
        transition: 1.3s;
        transition-delay: 0.5s;
    }
}