.modal-content:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 100%;
    left: 0;
    padding-top: 2%;
    background: url('/static/build/img/misc/overlay-top.svg');
    background-size: 100% auto;
    margin-bottom: -1px;
}

.modal-content:after {
    content: '';
    position: absolute;
    right: 0;
    top: 100%;
    left: 0;
    padding-top: 2%;
    background: url('/static/build/img/misc/overlay-bottom.svg');
    background-size: 100% auto;
    margin-top: -1px;
}

.modal-content {
    border: none;
    border-radius: 0;
}

.modal-header,
.modal-footer {
    border: none;
}

.modal__close {
    position: absolute;
    top: 10px;
    right: 10px;

    width: 35px;
    height: 35px;

    font-size: 21px;
    z-index: 10;


}