:root {
    --navbar-height: calc(130rem/16);
    --navbar-height-mobile: calc(103rem/16);
    --footer-height: calc(464rem/16);
}

html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}

body.overlay-open,
body.nav-open{
    overflow: hidden;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: calc(982rem/16);
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.container.container {
    max-width: calc(1479rem/16); /* 1455 + 24px padding */
    width: 100%;
    padding-right: calc(12rem/16);
    padding-left: calc(12rem/16);
}

.container-narrow{
    max-width: calc(692rem/16); /* 668px + 24px padding */
    width: 100%;
    padding-right: calc(12rem/16);
    padding-left: calc(12rem/16);
    margin: 0 auto;
}

.container-large{
    max-width: calc(1180rem/16);
    width: 100%;
    padding-right: calc(12rem/16);
    padding-left: calc(12rem/16);
    margin: 0 auto;
}

.container-medium{
    max-width: calc(850rem/16);
    width: 100%;
    padding-right: calc(12rem/16);
    padding-left: calc(12rem/16);
    margin: 0 auto;
}

.container-outer{
    max-width: calc(3100rem/16);
    width: 100%;
    margin: 0 auto;
}

.main-content{
    padding-top: 3.25rem;
    @media screen and (min-width: 768px){
        padding-top: 3.5rem;
    }
}

.main-content > .no-nav-padding:first-child,
.main-content > .pimcore_area_content:first-child > .no-nav-padding {
/*    margin-top: calc(-35rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(-8rem/16);
    }

    @media screen and (min-width: 1200px){
        margin-top: calc(-24rem/16);
    }

    @media screen and (min-width: 1400px){
        margin-top: calc(-50rem/16);
    }*/
}

.anchor-with-offset {
    display: block;
    border-top: 1px solid transparent;
    margin-top: calc(var(--navbar-height) * -1);
    padding-top: calc(var(--navbar-height));
    pointer-events: none;
    @media (max-width: 767px) {
        margin-top: calc(var(--navbar-height-mobile) * -1);
        padding-top: calc(var(--navbar-height-mobile));
    }
}

.main-content {
    @media screen and (min-width: 768px){
        min-height: calc(100vh - 108rem/16 - var(--footer-height) - 2.5rem); /*in case the page does not contain enough content */
    }
}