.recipe__preparation{
    margin-bottom: calc(60rem/16);
    @media screen and (min-width: 768px){
        margin-bottom: calc(120rem/16);
    }
}
.recipe__top{
    margin-bottom: calc(50rem/16);
    
    @media screen and (min-width: 768px){
       margin-bottom: calc(60rem/16);
    }

    @media screen and (min-width: 992px){
        margin-bottom: calc(120rem/16);
    }
}
.recipe__toptitle{
    font-size: calc(20rem/16);
}

.recipe__subtitle{
    text-transform: uppercase;
    font-size: calc(13rem/16);
    font-weight: 700;
}

.recipe__categories__item{
    background-color: #a2a2a2;
    text-transform: none;
    font-size: calc(15rem/16);
}

.recipe__metainfo{
    font-size: calc(15rem/16);
}

.recipe__metainfo__portions .form-control{
    height: calc(40rem/16);
    padding-top: calc(6rem/16);
}

.recipe__ingredients{
    margin-top: calc(30rem/16);
}

.recipe__ingredients__title{
    font-size: calc(25rem/16);
}

.recipe__ingredients__hl {
    font-weight: 700;
}

.recipe__ingredients a{
    position: relative;
    display: block;
    padding-right: calc(18rem/16);
}

.recipe__ingredients a>.icon{
    position: absolute;
    right: 0;
    top: 0;
}

.recipe__ingredients__list>li{
    padding: calc(4rem/16) calc(10rem/16);
    border-top: 1px solid #dfe0e1;
}

.recipe__ingredients__list>li>a .icon{
    font-size: calc(12rem/16);
    margin-top: calc(8rem/16);
}

.recipe__tags__item{
    text-transform: uppercase;
    font-size: calc(11rem/16);
}

.recipe__tags__item .icon{
    font-size: calc(14rem/16);
    margin-right: calc(5rem/16);
    position: relative;
    top: calc(3rem/16);
    color: var(--color-primary);
}

.recipe__share {
    @media screen and (min-width: 1760px) {
        position: absolute;
        transform-origin: bottom right;
        transform: rotate(90deg);
        margin: 0;
        right: -60vw;
        bottom: 0;
    }
}
.recipe__share-wrapper {
    @media screen and (min-width: 1760px) {
        padding: 0;
    }
}