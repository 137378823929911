:root {
    --color-rating-default: #f5b932;
    --color-rating-hover: #d89a2d;
    --color-rating-inactive: #f5d7a0;
}

.rating{
    display: contents;
}

.rating__form{
    display: flex;
    align-items: center;
}

.rating__text {
    margin-left: calc(10rem/16);
    @media (max-width: 767px) {
        font-size:.875rem;
    }
}

.rating__suns-icon {
    color: var(--color-rating-inactive);
    margin-right: .25rem;
}
.rating__suns-icon--full {
    color: var(--color-rating-default);
    margin-right: .25rem;
}
.rating__suns-item {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.rating__suns-item-wrapper input[type="radio"] {
    display:none;
}

.rating__suns{
    padding-top: 6px;
}

.rating__suns .chooser-wrapper {
    pointer-events:none;
    overflow:hidden;
    position: absolute;
    width: 100%;
    height: calc(24rem/16);
    top: 0;
    left: 0;
}
.rating__suns .chooser {
    pointer-events: none;
    height: 100%;
    width: 60%;
    transition: width 0.3s linear;
    overflow: hidden;
}
.rating__suns-item-wrapper {
    position: relative;
}

.rating__suns-label-1:hover ~ .chooser-wrapper .chooser {
    width: 15% !important;
}
.rating__suns-label-2:hover ~ .chooser-wrapper .chooser {
    width: 40% !important;
}
.rating__suns-label-3:hover ~ .chooser-wrapper .chooser {
    width: 60% !important;
}
.rating__suns-label-4:hover ~ .chooser-wrapper .chooser {
    width: 80% !important;
}
.rating__suns-label-5:hover ~ .chooser-wrapper .chooser {
    width: 100% !important;
}

.rating__suns-label:hover  ~ .chooser-wrapper .rating__suns-icon--full {
    color: var(--color-rating-hover);
}