.custom-radio:before,
.custom-radio:after {
    content: " ";
    display: table;
}.custom-radio:after {
     clear: both;
 }
.custom-radio label {
    cursor: pointer;
}
.custom-radio__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    position: relative;
    float: left;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: calc(1rem/16) solid var(--color-primary);
    background: #fff;
    width: calc(16rem/16);
    height: calc(16rem/16);
    margin-top: .2em;
    border-radius: 50%;
}
/*.custom-radio__box:before {*/
    /*content: '';*/
    /*position: absolute;*/
    /*top: 0;*/
    /*left: 0;*/
    /*width: 100%;*/
    /*height: 100%;*/
    /*border-radius: 50%;*/
    /*background: var(--color-primary);*/
    /*visibility: hidden;*/
/*}*/
.custom-radio__input:checked ~ .custom-radio__box {
    background: var(--color-primary);
}
.custom-radio__input:focus ~ .custom-radio__box {
    box-shadow: 0 0 3px 0 var(--color-primary);
}
.has-error.has-error .custom-radio__box {
    border-color: var(--color-danger)
}
.custom-radio__text {
    display: block;
    overflow: hidden;
}

/* big radio */
.custom-radio--big .custom-radio__box {
    height: 25px;
    width: 25px;
}

.custom-radio--big .custom-radio__text {
    font-size: calc(26rem/16);
    line-height: calc(31/28);
}

/* this class is to indent an element to align with the radio text*/
.custom-radio-additional-text {
    margin-left: calc(1rem + 7rem/16); /* width + margin */
}

/* box radio */
.custom-radio--box .custom-radio__label {
    width: 100%;
}

/* hide top border */
.custom-radio--box + .custom-radio--box {
    margin-top: -2px;
}

.custom-radio.disabled .custom-radio__box{
    border-color: #6b6c6d !important;
}
.custom-radio.disabled .custom-radio__text{
    color: #6b6c6d !important;
}
.custom-radio.disabled .custom-radio__text:hover, .custom-radio.disabled .custom-radio__box:hover {
    cursor: not-allowed !important;
}
.custom-radio.disabled label {
    cursor: default;
}

/* icon radio */
.custom-radio--icon .custom-radio__icon {
    color: var(--color-primary);
    opacity: 0.5;
}

.custom-radio--icon .custom-radio__input:checked ~ .custom-radio__icon{
    opacity: 1;
}

/* filter box radio */
.custom-radio--filter-box {
    overflow: hidden;  /* creates a block formatting context */
}

.custom-radio--filter-box .custom-radio__content {
    display: flex;
    align-items: center;

    padding: .5rem 1rem;
    min-height: calc(45rem/16);
    border: 1px solid var(--color-dark-grey);

    font-family: var(--font-default-bold);
    color: var(--color-dark-grey);
    text-transform: uppercase;
    font-size: calc(14rem/16);
}

.custom-radio--filter-box .custom-radio__input:checked ~ .custom-radio__content {
    color: black;
}

.custom-radio--filter-box .custom-radio__icon {
    font-size: calc(24rem/16);
    margin-right: calc(8rem/16);
}

.custom-radio--filter-box:hover .custom-radio__content {
    background: var(--color-light-grey);
}

