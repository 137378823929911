.img-teaser-slider .slick-slide>div{
    margin: 0 calc(12rem/16);
}

.content-img-teaser{
    position: relative;
    border-radius: calc(10rem/16);
    text-align: center;
    overflow: hidden;
}

.content-img-teaser__img img{
    border-radius: calc(10rem/16);
}

.content-img-teaser__title{

    position: absolute;
   
    width: 100%;
    font-size: calc(26rem/16);
    line-height: calc(36/28);

    @media screen and (min-width: 768px){
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        
        transition: 0.6s;
        transition-delay: 0.1s;
        opacity: 1;
    }
    
    @media screen and (min-width: 992px){
        font-size: calc(28rem/16);
    }
    
    @media screen and (max-width: 767px){
        top: calc(40rem/16);
        left: 50%;
        transform: translateX(-50%);
    }

}

.content-img-teaser:hover .content-img-teaser__title{
    transform: translateX(-50%) translateY(-120%);
    transition-delay: 0s;
    opacity: 0;
}

.content-img-teaser__title__line{
    background-color: #fff;
    padding: calc(3rem/16) calc(10rem/16);
    border-radius: calc(10rem/16);
}

.content-img-teaser__content{
    position: absolute;

    width: 100%;
    background-color: rgba(255,255,255,0.8);
    padding: calc(10rem/16) calc(30rem/16);
    font-size: calc(16rem/16);
    line-height: calc(18/16);

    @media screen and (min-width: 768px){
        top: 50%;
        transform: translateZ(0) translateY(15%);
        transition: 0.6s;
        transition-delay: 0s;
        opacity: 0;
    }

    @media screen and (max-width: 767px){
        bottom: 0;
    }
}

.content-img-teaser:hover .content-img-teaser__content{
    @media screen and (min-width: 768px){
        transform: translateZ(0) translateY(-50%);
        opacity: 1;
    }

}