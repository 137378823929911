.grid-promotion-teaser {
    position: relative;
}
@media screen and (min-width: 768px){
    .grid-promotion-teaser {
        transition: all 200ms ease;
    }
    .grid-promotion-teaser:hover {
        transform: translateY(-.5rem) scale(1.01);
    }
}
.grid-promotion-teaser__inner {
    position: relative;
    font-size: 1.5rem;
    line-height: 1;
    font-family: var(--font-handwriting);
    background:var(--color-primary);
    color:#fff;
    clip-path: polygon(1% 1%, 21% 0, 37% 0%, 50% 1%, 67% 0%, 84% 1%, 99% 0%, 100% 78%, 99% 100%, 69% 100%, 50% 99%, 27% 100%, 0% 99%, 0% 45%);
    @media screen and (min-width: 1400px){
        font-size: 2rem;
    }
}
.grid-promotion-teaser__inner--large {
    font-size: 2rem;
    @media screen and (min-width: 1400px){
        font-size: 2.5rem;
    }
}
.grid-promotion-teaser__inner--small {
    font-size: 1.125rem;
    @media screen and (min-width: 1400px){
        font-size: 1.5rem;
    }
}
.grid-promotion-teaser__body {
    padding:0 1rem 3rem 1rem;
    overflow: hidden;
}
.grid-promotion-teaser__image-arrow {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;

    border-right:20px solid var(--color-primary);
    position: absolute;
    right:-.125rem;
    top:50%;
    transform: translateY(-50%);
}


.grid-promotion-teaser__price-tag {
    position: absolute;
    right:-1rem;
    bottom:1rem;
    height:calc(40rem/16);
    line-height:calc(40rem/16);
    font-size: 1.125rem;
    white-space: nowrap;
}
.grid-promotion-teaser__price-tag-price {
    display: inline-block;
    width:calc(100rem/16);
    height:calc(40rem/16);
    line-height:calc(40rem/16);
    text-align: center;
    color:#fff;
    font-size: 1.25rem;
    font-family: var(--font-handwriting);
    background: url(/static/img/bg/pricetag.svg) 50% 50% no-repeat;
    background-size: contain;
}


/* green */
.grid-promotion-teaser--green .grid-promotion-teaser__image-arrow {
    border-right:20px solid #B8BF7A;
}
.grid-promotion-teaser--green .grid-promotion-teaser__inner {
    background: linear-gradient(90deg, rgba(184,191,122,0) 0%, rgba(184,191,122,0) 50%, rgba(184,191,122,1) 50%, rgba(184,191,122,1) 100%);
}

/* turquoise */
.grid-promotion-teaser--turquoise .grid-promotion-teaser__image-arrow {
    border-right:20px solid #77BFA4;
}
.grid-promotion-teaser--turquoise .grid-promotion-teaser__inner {
    background: linear-gradient(90deg, rgba(119,191,164,0) 0%, rgba(119,191,164,0) 50%, rgba(119,191,164,1) 50%, rgba(119,191,164,1) 100%);
}

/* blue */
.grid-promotion-teaser--blue .grid-promotion-teaser__image-arrow {
    border-right:20px solid #7492CA;
}
.grid-promotion-teaser--blue .grid-promotion-teaser__inner {
    background: linear-gradient(90deg, rgba(116,146,202,0) 0%, rgba(116,146,202,0) 50%, rgba(116,146,202,1) 50%, rgba(116,146,202,1) 100%);
}

/* red */
.grid-promotion-teaser--red .grid-promotion-teaser__image-arrow {
    border-right:20px solid #E0615F;
}
.grid-promotion-teaser--red .grid-promotion-teaser__inner {
    background: linear-gradient(90deg, rgba(224,97,95,0) 0%, rgba(224,97,95,0) 50%, rgba(224,97,95,1) 50%, rgba(224,97,95,1) 100%);
}