.gifttext-message {
    background-color: #eee;
    height: 130px;
    resize: none;
    padding: 10px;
    border: none;
}

.gifttext-box {
    box-shadow: 1px 1px 10px 1px rgba(179,179,179,1);
    border-radius: 5px;
    border-top-right-radius: 0;

    padding: 25px 10px 10px;

    overflow: hidden;
}

.gifttext-message.has-error {
    border: 1px solid var(--color-danger);
    outline: none;
}