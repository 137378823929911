
.container-navbar{

    @media screen and (min-width: 1200px){
        margin-left: 17%;
    }
    @media screen and (min-width: 768px){
        margin-left: calc(140rem/16);

    }
}

.navbar-brand{
    position: absolute;
    top: 0;
    padding: 0;
    z-index: 21;
    transform: translateY(-38px);
    transition: transform .3s ease;

    @media screen and (max-width: 767px){
        left: 50%;
        transform: translateX(-50%);
        margin: 0;
    }
}
@media screen and (min-width: 768px) and (max-width: 1399px) {
    .is-affix .navbar-brand {
        transform: translateY(-0px);
    }
}

.navbar-brand img {

    width: calc(100rem/16);

    @media screen and (min-width: 1400px){
        width: auto;
    }
    @media screen and (min-width: 1200px){
        width: calc(160rem/16);
    }
    @media screen and (min-width: 768px){
       width: calc(130rem/16);
    }
}

.navbar__shop-link {
    position: absolute;
    left: calc(70rem/16);
    top: 0;
    width: calc(60rem/16);
}

.navbar__shop-link:hover {
    transform: scale(1.05);
}

@media (max-width: 767px) {
    .navbar-brand{
        transition: transform .3s,
                    opacity .2s;
        transform: translate(-50%, 0);
        opacity: 1;
    }

    .is-affix .navbar-brand{
        transform: translate(-50%, -100%);
        opacity: 0;
    }

    .navbar-brand-affix {
        position: absolute;
        top: 14px;
        left: 50%;
        transform: translate(-50%, 20px);

        transition: all .3s ease;

        opacity: 0;
    }

    .navbar-brand-affix img {
        width:100%;
    }

    .is-affix .navbar-brand-affix {
        transition: all .3s ease .2s;
        transform: translate(-50%, 0);
        opacity: 1;
    }

}

@media (min-width: 390px) and (max-width: 767px) {
    .navbar-brand-affix {
        width: 7.5rem;
    }
}

@media (max-width: 389px) {
    .navbar-brand-affix {
        width: 6rem;
    }
}

@media (min-width: 768px) {
    .navbar-brand-affix {
        display: none;
    }
    .navbar__shop-link {
        display: none;
    }
}

.navbar--main{
    height: calc(60rem/16);
    position: absolute;
    left:0;
    right:0;
    z-index: 26;

    @media screen and (min-width: 768px){
        height: calc(70rem/16);
  }
    
    @media screen and (max-width: 767px){
        padding: 0 calc(5rem/16);
        transform: none!important; /** wegen affix inline translateZ(0) **/
    }
}

.navbar--main:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-primary);
    z-index: -1;
    clip-path: polygon(0 100%, 17% 94%, 26% 89%, 36% 87%, 47% 100%, 53% 87%, 66% 87%, 72% 100%, 82% 87%, 87% 93%, 94% 93%, 100% 83%, 100% 0, 0 0);
    @media screen and (max-width: 767px){
        clip-path: polygon(0 0, 100% 0, 100% 89%, 83% 90%, 69% 98%, 53% 89%, 40% 95%, 0 100%);
    }
}
.navbar--main:after{
    content: '';
    position: absolute;
    top: 0;
    height: 2rem;
    right: 0;
    left:0;
    width: auto;
    z-index: -2;
    box-shadow: 0 30px 30px 0 rgba(0,0,0,.33);
    @media screen and (max-width: 767px){
        box-shadow: 0 22px 22px 0 rgba(0,0,0,.33);
    }
}

@media screen and (min-width: 768px){
    .navbar--main .navbar-nav .nav-item{
        position: relative;
    }
    .navbar--main .navbar-nav .nav-item:hover,
    .navbar--main .navbar-nav .nav-item:focus{
        position: static;
    }
    .navbar--main .navbar-nav .nav-item:hover .nav-link:before,
    .navbar--main .navbar-nav .nav-item:focus .nav-link:before,
    .navbar--main .navbar-nav .nav-item.is-active .nav-link:before{
        content:'';
        position: absolute;
        top:2.625rem;
        left:calc(15rem/16);
        right:calc(15rem/16);
        display: block;
        width: auto;
        height: calc(6rem/16);
        pointer-events: none;
        background: url(/static/img/nav-linie.svg) 50% 50% no-repeat;
        background-size: cover;
    }

}

@media screen and (min-width: 1200px){
    .navbar--main .navbar-nav .nav-item:hover .nav-link:before,
    .navbar--main .navbar-nav .nav-item:focus .nav-link:before,
    .navbar--main .navbar-nav .nav-item.is-active .nav-link:before{
        left:calc(24rem/16);
        right:calc(24rem/16);
    }
}

.navbar--main .navbar-nav .nav-link{
    padding: calc(18rem/16) calc(15rem/16) calc(26rem/16) calc(15rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    position: relative;

    @media screen and (min-width: 1200px){
        padding: calc(18rem/16) calc(24rem/16) calc(26rem/16) calc(24rem/16);
        font-size: calc(18rem/16);
    }
    
    @media screen and (min-width: 768px){
        font-size: calc(15rem/16);
        color:#fff;
    }
    
    @media screen and (max-width: 767px){
        text-align: left;
        padding: calc(15rem/16);
    }
}

.navbar--main .navbar-nav>li>.nav-link:before {
    @media screen and (min-width: 992px){
        content: '';
        position: absolute;
        top: calc(28rem/16);
        bottom: calc(-15rem/16);
        width: calc(25rem/16);
        left: 0;
        display: none;
        z-index: 5;
    }

    @media screen and (min-width: 1400px) {
        width: calc(45rem / 16);
        left: calc(-18rem/16);
    }
}

.navbar--main .navbar-nav>li:hover>.nav-link:before {
    @media screen and (min-width: 992px){
        display: block;
    }
}

.navbar--main .navbar-nav>li>.nav-link:after {
    @media screen and (min-width: 992px){
        content: '';
        position: absolute;
        top: calc(28rem/16);
        bottom: calc(-15rem/16);
        width: calc(25rem/16);
        right: calc(-19rem/16);
        display: none;
        z-index: 5;

    }

    @media screen and (min-width: 1400px){
        width: calc(45rem/16);
        right: calc(-18rem/16);
    }

}
.navbar--main .navbar-nav>li:hover>.nav-link:after {
    @media screen and (min-width: 992px){
        display: block;
    }
}

/*@media screen and (max-width: 767px){
    !* makes the navbar background overlap the navbar-collapse *!
    .navbar--main:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: inherit;
        z-index: 0;
    }
}*/
.navbar-toggler{
    position: absolute;
    left:1rem;
    top:.25rem;
    padding: 5px 0;
    width: calc(45rem/16);
    height: calc(50rem/16);
    float: right;
}
.navbar-toggler__bar {
    display: block;
    width: calc(30rem/16);
    height: calc(3rem/16);
    background: #fff;
    transition: transform .25s ease-in-out,opacity .25s ease-in-out;
    border-radius: calc(2rem/16);
    margin: 0 auto;
}

.navbar-toggler__bar+.navbar-toggler__bar {
    margin-top: calc(6rem/16);
}


.navbar-toggler.is-open .navbar-toggler__bar:first-child {
    transform: rotate(-45deg) translateX(-6px) translateY(6px);
    width: calc(30rem/16);
}

.navbar-toggler.is-open .navbar-toggler__bar:nth-child(2) {
    opacity: 0;
}

.navbar-toggler.is-open .navbar-toggler__bar:nth-child(3) {
    transform: rotate(45deg) translateX(-6px) translateY(-6px);
    width: calc(30rem/16);
}

@media screen and (max-width: 767px){
    .navbar-collapse {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 6.9375rem;
        background: #fff;
        color: var(--color-default);
        transform: translateX(-100%);
        transition: transform .25s ease;
        /* padding-top: 50 + 38 for top-navbar */
        padding: calc(88rem/16) calc(20rem/16) calc(20rem/16);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        /*z-index: -1;*/
    }

    .is-affix .navbar-collapse {
        top: 3.25rem;
        padding-top:1.5rem;
        z-index: -2;
    }

    .nav-open .navbar-collapse{
        transform: translateX(0);
    }

    .subnav-toggle.icon{
        position: absolute;
        right: calc(5rem/16);
        top: 50%;
        transform: translateY(-50%);
        font-size: calc(9rem/16);
        z-index: 25;
        padding: calc(10rem/16);
        transition: transform .2s;
    }

    .navbar-collapse li.is-open>a>.subnav-toggle,
    .navbar-collapse li.is-open>.navbar__subnav__hl>a>.subnav-toggle {
        transform: translateY(-50%) scaleY(-1);
    }

    .navbar__subnav .subnav-toggle.icon{
        right: calc(-25rem/16);
    }
    
    .navbar-bottom{
        padding: calc(15rem/16);
    }

    .navbar--bottom__item{
        font-size: calc(14rem/16);
        line-height: calc(20/14);
        display: flex;
        align-items: center;
        margin-bottom: calc(15rem/16);
    }

    .navbar--bottom__item-icon-circle{
        font-size: calc(22rem/16);
        margin-right: calc(10rem/16);
        position: relative;
        color: var(--color-primary);
    }

    .navbar--bottom__item-icon-circle .icon{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        color: #fff;
        font-size: calc(14rem/16);
    }
    .navbar--bottom__item-icon-circle .icon.icon-mail{
        font-size: calc(9rem/16);
    }

    .dropdown--lang__flag{
        border-radius: 50%;
        margin-right: 7px;
    }

    .dropdown--lang .dropdown-toggle:after{
        display: none;
    }
    
}
/* Subnav dropdown */

.navbar__subnav{
    left: 0;
    right: 0;
    top: 99%;
    background-color: #fff;
    color: var(--color-default);
    z-index: 10;
    padding: 0 calc(15rem/16);
    display: none;

    @media screen and (min-width: 768px){
        position: absolute;
        padding: calc(38rem/16);
        top:86%;
        box-shadow: 0 8px 8px 0 rgba(0,0,0,0.19);
        z-index: -2;
    }
    
    @media screen and (max-width: 767px){
       text-align: left;
    }
}

.navbar--main .navbar-nav>li.is-open .navbar__subnav{
    @media screen and (min-width: 768px){
        display: block;
    }
}

.navbar--main .navbar-nav>li:hover .navbar__subnav{
    @media screen and (min-width: 992px){
        display: block;
    }
}


.navbar__subnav__hl{
    font-size: calc(15rem/16);
    font-family: var(--font-default-bold);
    color: var(--color-dark-grey);
    text-transform: uppercase;
    margin-bottom: calc(20rem/16);
    position: relative;
    @media screen and (min-width: 992px) {
        font-size: calc(15rem / 16);
    }
    @media screen and (min-width: 1200px){
        font-size: calc(16rem/16);
    }
}

.navbar__subnav__hl:hover{
    color: var(--color-default);
}

.navbar__subnav__hl .icon{
    font-size: calc(24rem/16);
    vertical-align: calc(-6rem/16);
    margin-right: calc(3rem/16);

    @media screen and (min-width: 992px){
        font-size: calc(30rem/16);
    }
}

.navbar__subnav__list{
    font-size: calc(16rem/16);

    @media (min-width: 768px) {
        font-size: calc(15rem/16);
    }

    @media (min-width: 1200px) {
        font-size: calc(16rem/16);
    }
    @media screen and (max-width: 767px){
       display: none;
        padding: 0 calc(20rem/16) calc(20rem/16);
    }
}

.navbar__subnav__list li{
    margin-bottom: calc(7rem/16);
}

.navbar__subnav__list li.active>a {
    font-family: var(--font-default-bold);
    color: black;
}
.navbar__subnav__list li.active>a,
.navbar__subnav__list li>a:hover{
    @media (max-width: 767px) {
        font-family: var(--font-default-bold);
    }
    @media (min-width: 768px) {
        color: var(--color-primary-text);
    }
}

@media (max-width: 767px) {
    .navbar-nav li a:focus {
        outline: none;
    }
}

/* navbar right */
@media (max-width: 767px) {
    .navbar-right {
        position: absolute;
        right:1rem;
        top:0;
        white-space: nowrap;
    }
    .dropdown--navbar-right-item {
        white-space: normal;
    }
}


.navbar-right .list-inline-item:not(:last-child){
    margin-right: calc(15rem/16);

    @media screen and (min-width: 1400px){
        margin-right: calc(22rem/16);
    }
}

.navbar-right__item,
.btn.navbar-right__item{
    background: url(/static/img/btn/circle-filled.svg);
    background-repeat: no-repeat;
    border: none;
    width: calc(35rem/16);
    height: calc(35rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-primary);
    font-size: calc(23rem/16);
    transition: transform 150ms ease;
    transform: translateY(0);
    cursor: pointer;

    @media screen and (min-width: 1400px){
        width: calc(44rem/16);
        height: calc(44rem/16);
        font-size: calc(28rem/16);
    }
}

.navbar-right__item:hover{
    color: var(--color-primary);
    transform: translateY(-2px);
}

.navbar-right__item__hint {
    background: url(/cache-buster-1554469915939/static/build/img/btn/circle-filled.svg);
    font-size: 11px;
    position: absolute;
    right: -5px;
    top: -5px;
    width: 20px;
    height: 20px;
}

.navbar__search-btn.navbar__search-btn {
    position: relative;
    height: 46px;
    width: 46px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 768px) {
        right: 46px;
    }

}


/* mobile visible search bar */
@media screen and (max-width: 767px) {

    /*body:not(.is-checkout) .navbar--main:not(.is-affix) .dropdown-menu--search {
        display: block;
    }*/

    .navbar--reduced-logo .navbar-brand-affix {
        opacity: 1;
        transform: translate(-50%);
    }

    .navbar--reduced-logo .navbar-brand {
        transform: translate(-50%,-100%);
        opacity: 0;
    }

    .navbar-collapse {
        padding-top: .5rem;
    }

    /*.dropdown-menu--search.dropdown-menu--search {
        border-left: none;
        border-right: none;
        padding: calc(5rem/16);
    }*/

    .navbar__search-btn.navbar__search-btn {
        height: 39px;
        font-size: calc(16rem/16);
    }

    .navbar__search-form .form-control {
        padding-left: calc(24rem/16);
        padding-top: calc(8rem/16);
        height: calc(38rem/16);

        /* for correct inline-layout*/
        border: none;
        width: 100%;
    }
    body:not(.is-checkout) .guidepost {
        padding-top: 38px;
        margin-bottom: -52px;
    }

    body:not(.is-checkout) .main-content > .no-nav-padding:first-child,
    body:not(.is-checkout) .main-content > .pimcore_area_snippet:first-child .no-nav-padding,
    body:not(.is-checkout) .main-content > .pimcore_area_content:first-child > .no-nav-padding {
        margin-top: -5px;
    }

    .navbar__search-form-icon {
        position: absolute;
        display: block !important;
        top: 1rem;
        left: 1rem;
    }
}