.wash-bg-svg{
    display: block;
    position: absolute;
    top: 0;
    left: auto;
    right: -9%;
    z-index: 1;
    width: 109%;
    height: 100%;
}

.is-right .wash-bg-svg{
    transform: scale(-1, 1);
    right: auto;
    left: -9%;
}

.wash-bg-svg--small{
    right: calc(-35rem/16);
    width: calc(100% + 23px);

}

.bg-orange-pastel {
    background: var(--color-orange-pastel);
}

.bg-wood {
    background-image: url('/static/img/bg/light-wood.jpg');
    background-color: transparent;
    background-repeat: repeat;
    background-size: 100%;
}

.bg-gray {
    background: var(--color-light-grey);
}