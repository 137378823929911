
.configurator-selection {
    position: relative ;
    border: 1px solid #d5d5d5;
    padding: .75rem .5rem .5rem;
    @media (max-width: 767px) {
        padding-bottom: 2.3rem
    }
}

@media (min-width: 768px) {
    .configurator-selection:not(.is-affix) {
        padding: 15px;
    }
}

.configurator-selection-item {
    max-width: 130px;
    margin: 0 auto;
    text-align: center;
}

.configurator-selection:not(.is-affix) .configurator-selection-item {
    @media (min-width: 768px) {
        min-height: calc(196rem/16);
    }
}

.configurator-selection-item__number {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--color-orange);
    opacity: .5;
    font-size: 8vw;
    font-family: var(--font-default-bold);

    z-index: 1;
    @media (min-width: 768px) {
        font-size: 75px;

    }
}

.configurator-selection-item.filled .configurator-selection-item__number {
    color: white;
}

.configurator-selection-item__circle {
    position: relative;
    background: white;
    box-shadow: 1px 1px 10px 0 rgba(50,50,50,0.5);
    border-radius: 100%;
    border: 3px solid var(--color-orange);
    overflow: hidden;
    @media (min-width: 768px) {
        border-width: 5px;
        margin-bottom: 15px;
    }
}

.configurator-selection-item__heading {
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    font-size: 13px;
}

.configurator-selection-item__subheading {
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    font-size: 11px;
}

.configurator-selection.is-affix .hide-if-affix {
    display: none;
}

@media (max-width: 767px) {
    .configurator-selection-item__heading,
    .configurator-selection-item__subheading {
        display: none;
    }

}

.configurator-selection.is-affix {
    position: fixed;
    bottom: 0;
    left: 0 !important;
    right: 0;
    top: auto !important;
    width: auto !important;
    z-index: 2;
}

.configurator-selection__reset-btn {
    position: absolute;
    bottom: 0;
    right: 10px;
    @media (min-width: 768px) {
        bottom: 10px;
    }
}

.configurator-selection__text {
    align-self: center;
}

@media (max-width: 1199px) {
    .is-affix .configurator-selection__text-before {
        display: none;
    }

}

@media (max-width: 768px) {
    .is-affix .configurator-selection__text {
        display: none;
    }
}

.configurator-selection__next-btn-mobile {
    position: absolute;
    left: 5px;
    bottom: 5px;

    @media (min-width: 768px) {
        display: none;
    }
}

@media (max-width: 767px) {
    .configurator-type-link--bordered {
        border-bottom: 1px solid var(--color-gray-border);
        margin-bottom: .75rem;
        padding-bottom: .25rem;
    }

}