.form-group {
  position: relative;
}

.floating-label {
  position: absolute;
  left: calc(10rem/16);
  top: calc(22rem/16);
  transform: translateY(-50%);
  transition: transform 200ms ease-in-out;
  transform-origin: left top;
  pointer-events: none;
  z-index: 4;
  font-size: calc(16rem/16);
  background-color: #fff;
  padding-left: 3px;
  /*font-family: var(--font-handwriting);*/
  text-transform: uppercase;
  color: #545454;
}

.form-group--small-label .floating-label {
  text-transform: none;
  font-size: calc(16rem/16);

  @media screen and (min-width: 992px){
    font-size: calc(17rem/16);
  }
}

.form-group--small .floating-label {
    left: .25rem;
}

.form-control:focus{
  box-shadow: none;
}

.form-control:focus ~ .floating-label,
.form-control.has-value ~ .floating-label,
select ~ .floating-label,
select + .floating-label {
  transform: translateY(-90%) scale(.62);

  @media screen and (min-width: 768px){
    transform: translateY(-90%) scale(.62);
  }
}

.form-control:-webkit-autofill ~ .floating-label,
.form-control:-webkit-autofill:focus ~ .floating-label{
  transform: translateY(-90%) scale(.62);

  @media screen and (min-width: 768px){
    transform: translateY(-90%) scale(.62);
  }
}

.form-group--small-label .form-control:focus ~ .floating-label,
.form-group--small-label .form-control.has-value ~ .floating-label,
.form-group--small-label select ~ .floating-label,
.form-group--small-label select + .floating-label {
  transform: translateY(-120%) scale(.7);

  @media screen and (min-width: 768px){
    transform: translateY(-120%) scale(.7);
  }
}


.form-group--standard .form-control:focus ~ .floating-label,
.form-group--standard .form-control.has-value ~ .floating-label,
.form-group--standard.has-value .floating-label,
.form-group--standard select ~ .floating-label,
.form-group--standard select + .floating-label {
  transform: translateY(-115%) scale(.62);

  @media screen and (min-width: 768px){
    transform: translateY(-115%) scale(.62);
  }
}


textarea ~ .floating-label{
  top: calc(22rem/16);
}


.form-control:focus ~ .floating-label.floating-label--textarea,
select ~ .floating-label.floating-label--textarea,
.form-control.has-value ~ .floating-label.floating-label--textarea {
  transform: translateY(-90%) scale(.62);

  @media screen and (min-width: 768px){
    transform: translateY(-95%) scale(.62);
  }
}