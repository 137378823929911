.offscreen-overlay{
    display: block;
    position: fixed;
    background: #fff;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 30;
    transform: translateX(100%);
    transition: transform .2s ease-in-out;
    overflow-y: scroll;
}
.offscreen-overlay__container{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 20;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: calc(50rem/16);
}

.offscreen-overlay.is-open{
    transform: translateX(0);
}

.offscreen-overlay__top{
    display: block;
    padding: 12px 50px;
    background: #e6e6e6;
    color: var(--color-dark-grey);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    font-size: calc(14rem/16);
    text-align: center;
    position: relative;
}

.offscreen-overlay__back-icon{
    position: absolute;
    left: calc(30rem/16);
    top: 50%;
    transform: translateY(-50%);
}

.offscreen-overlay__cat-icon{
    font-size: calc(19rem/16);
    vertical-align: calc(-4rem/16);
}

.offscreen-overlay__body{
    padding: calc(20rem/16) calc(15rem/16);
}

.offscreen-overlay__fixed-btn{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 25;
}

.offscreen-overlay__fixed-btn .btn{
    padding: calc(10rem/16) calc(18rem/16);
    font-size: calc(14rem/16);
    width: 100%;
}

.offscreen-overlay__link-list>li{
    color: var(--color-dark-grey);
    text-transform: uppercase;
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    display: flex;
    align-items: center;
    border-top: 1px solid #ECEAEA;
}

.offscreen-overlay__link-list__item{
    display: flex;
    align-items: center;
    position: relative;
    padding: calc(15rem/16) 0;
    padding-right: calc(32rem/16);
    width: 100%;
}

.offscreen-overlay__link-list__item__icon{
    font-size: calc(24rem/16);
    margin-right: calc(10rem/16);

}

.offscreen-overlay__link-list__item__toggle{
    position: absolute;
    right: calc(-5rem/16);
    top: calc(10rem/16);
    padding: calc(10rem/16);
}

.offscreen-overlay__sub li{
    margin-bottom: calc(5rem/16);
}

.offscreen-overlay__sub li>a{
    color: var(--color-default);
    font-family: var(--font-default);
    font-size: calc(15rem/16);
    padding: calc(8rem/16) 0;
    text-transform: none;
    display: block;
}