.img-text-item {
    margin-bottom: calc(30rem/16);
}
.img-text-item__img {
    border-radius: 50%;
    background-color: #ffffff;
}
.img-text-item__text {
    font-size: calc(19rem/16);
    margin-top: calc(5rem/16);

    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
    }
}

