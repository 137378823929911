.btn-wishlist:hover {
    color: var(--color-primary);
}

.btn-wishlist.is-active .icon:before{
    content: var(--icon-merkliste-filled);
    color: var(--color-primary);
}

.btn-wishlist.is-active:hover .icon:before{
    color: black;
}
